import { useState } from "react";
import { BookmarkIcon, PersonIcon } from "@radix-ui/react-icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useDeviceType from "../../Context/useDeviceType";
import useUserHook from "../../Context/UseUserContext";
import Dropdown from "../common/Dropdown/Dropdown";
import LogoutButton from "../Login/Logout";

const HeaderMobile = () => {
  const { t } = useTranslation();
  const useUser = useUserHook();

  const [menuItems, setMenuItems] = useState(false);

  return (
    <div className="fixed right-1/2 left-1/2 -translate-x-1/2 flex flex-col w-[95vw] mt-8 bg-white font-bold text-2xl shadow-theme rounded-lg z-10">
      <div className="flex w-full justify-between min-h-[64px] items-center px-4">
        <Link className="flex" to="/">
          <span className="text-black">
            {process.env.REACT_APP_TITLE ?? "Brand Name"}
          </span>
          <div className="flex">
            <span className="px-1 leading-3 text-black text-[10px]">Conea</span>
          </div>
        </Link>
        <button
          className="flex flex-row text-black gap-1 h-full items-center"
          onClick={(e) => {
            e.preventDefault();
            setMenuItems(!menuItems);
          }}
        >
          <span className="flex w-2 h-2 rounded-full bg-black"></span>
          <span className="flex w-2 h-2 rounded-full bg-black"></span>
          <span className="flex w-2 h-2 rounded-full bg-black"></span>
        </button>
      </div>
      {menuItems && (
        <div className="flex flex-col border-t-[1px] shadow-theme gap-2 py-4">
          <Link
            to={"/person"}
            className="px-4 text-xl font-bold text-theme-person border-r-gray-200 tracking-normal"
          >
            {t("header_menu_person")}
          </Link>
          <Link
            to={"/company"}
            className="px-4 text-xl font-bold text-theme-company border-r-gray-200 tracking-normal"
          >
            {t("header_menu_company")}
          </Link>
          <Link
            to={"/distributor"}
            className="px-4 text-xl font-bold text-theme-distributor border-r-gray-200 tracking-normal"
          >
            {t("header_menu_distributor")}
          </Link>
          {(useUser?.user?.role === "ADMIN" ||
            useUser?.user?.role === "OWNER") && (
            <div className="flex flex-col gap-2">
              <Link
                to={"/tags"}
                className={
                  "flex items-center gap-2 px-4 text-xl font-bold text-theme-tags border-r-gray-200 tracking-normal"
                }
              >
                {t("header_menu_tags")}
              </Link>
              <Link to="/users">
                <button
                  className={
                    "flex items-center gap-2 px-4 text-xl font-bold text-black border-r-gray-200 tracking-normal"
                  }
                >
                  {t("users_link")}
                </button>
              </Link>
            </div>
          )}
          <LogoutButton customClass={"text-xl px-4 py-0"} icon={<></>} />
        </div>
      )}
    </div>
  );
};

const HeaderDesktop = () => {
  const { t } = useTranslation();
  const useUser = useUserHook();

  return (
    <div className="m-auto flex flex-wrap justify-between items-center xl:max-w-[1200px] 2xl:max-w-[1640px] w-[95vw] h-16 mt-8 bg-white font-bold text-2xl shadow-theme rounded-lg">
      <Link className="ml-12 flex" to="/">
        <span className="text-black">
          {process.env.REACT_APP_TITLE ?? "Brand Name"}
        </span>
        <div className="flex">
          <span className="px-1 leading-3 text-black text-[10px]">Conea</span>
        </div>
      </Link>
      <div className="flex flex-row mr-12 items-center">
        <>
          <Link
            to={"/person"}
            className="px-4 text-xl font-bold text-theme-person border-r-gray-200 border-r-[1px] tracking-normal"
          >
            {t("header_menu_person")}
          </Link>
          <Link
            to={"/company"}
            className="px-4 text-xl font-bold text-theme-company border-r-gray-200 border-r-[1px] tracking-normal"
          >
            {t("header_menu_company")}
          </Link>
          <Link
            to={"/distributor"}
            className="px-4 text-xl font-bold text-theme-distributor border-r-gray-200 border-r-[1px] tracking-normal"
          >
            {t("header_menu_distributor")}
          </Link>
        </>

        <Dropdown
          button={
            <button className="flex flex-row px-4 text-[20px] text-black align-middle gap-1 h-full">
              <span className="flex w-2 h-2 rounded-full bg-black"></span>
              <span className="flex w-2 h-2 rounded-full bg-black"></span>
              <span className="flex w-2 h-2 rounded-full bg-black"></span>
            </button>
          }
          children={
            <>
              {(useUser?.user?.role === "ADMIN" ||
                useUser?.user?.role === "OWNER") && (
                <div>
                  <Link
                    to={"/tags"}
                    className={
                      "flex gap-2 cursor-pointer select-none items-center rounded-md px-2 py-2 outline-none text-theme-tags focus:bg-gray-50"
                    }
                  >
                    <BookmarkIcon />
                    {t("header_menu_tags")}
                  </Link>
                  <Link to="/users">
                    <button
                      className={
                        "flex gap-2 cursor-pointer select-none items-center rounded-md px-2 py-2 outline-none text-gray-700 focus:bg-gray-50"
                      }
                    >
                      <PersonIcon />
                      {t("users_link")}
                    </button>
                  </Link>
                </div>
              )}

              <LogoutButton />
            </>
          }
        />
      </div>
    </div>
  );
};

const Header = () => {
  const device = useDeviceType();

  return <>{device === "Phone" ? <HeaderMobile /> : <HeaderDesktop />}</>;
};

export default Header;
