import { Combobox } from "@headlessui/react";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Item } from "./SelectForm/SelectForm";

interface Props {
  selectedCompany: Item | null;
  setSelectedCompany: Dispatch<SetStateAction<Item | null>>;
  setQuery: Dispatch<SetStateAction<string>>;
  query: string;
  items: Item[];
  disabled?: boolean;
  fullWidth?: boolean;
}

const CompanyComboBox = ({
  selectedCompany,
  setSelectedCompany,
  setQuery,
  query,
  items,
  disabled = false,
  fullWidth,
}: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedCompany !== null) {
      setQuery(selectedCompany.name);
    }
  }, [selectedCompany, setQuery]);

  return (
    <Combobox value={query} disabled={disabled}>
      <div
        className={`relative flex flex-col text-black ${fullWidth && "w-full"}`}
      >
        <label className="flex flex-row items-start text-base font-bold pb-2 gap-1">
          {t("form_person_business_data_medium")}
        </label>
        <Combobox.Input
          onChange={(event) => setQuery(event.target.value)}
          name={"selected-company"}
          id={"selected-company"}
          placeholder={t("form_person_business_data_medium")}
          className={`w-full py-1 px-1 pl-4 border-[1px] border-theme-distributor rounded-lg`}
          value={query}
          autoComplete="off"
          onClick={() => {
            setSelectedCompany(null);
            setQuery("");
          }}
        />
      </div>
      <Combobox.Options className="border-[1px] border-theme-distributor rounded-md mt-1 absolute z-20 bg-white cursor-pointer">
        {items?.map((content) => (
          <div key={content?.id}>
            {query?.length > 0 && (
              <Combobox.Option
                value={content?.id}
                className={`rounded-lg border-theme-distributor px-4 py-2 hover:bg-theme-distributor`}
                onClick={() => {
                  setSelectedCompany({
                    id: content?.id,
                    name: content?.name,
                  });
                  setQuery(content?.name);
                }}
              >
                <span>{content?.name}</span>
              </Combobox.Option>
            )}
          </div>
        ))}
      </Combobox.Options>
    </Combobox>
  );
};

export default CompanyComboBox;
