import { Dispatch, SetStateAction, useState } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import {
  Content,
  Content as PeopleContent,
  PeopleT,
} from "../../../types/People";
import Spinner from "../../common/Spinner/Spinner";
import { t } from "i18next";
import MainTable from "../../common/MainTable/MainTable";

import { Dialog } from "../../common/Dialog/Dialog";
import EmptyResults from "../../common/EmptyState/EmptyResults";

export interface SortState {
  sortName: string;
  sortValue: string;
}

interface RowEditDialogProps {
  row: Content | any;
  value: string;
}

const columnHelper = createColumnHelper<PeopleContent>();

const RowEditDialog = ({ row, value }: RowEditDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      button={<span className="cursor-pointer break-all">{value}</span>}
      formType="person"
      edit={row}
    />
  );
};

const columns = [
  columnHelper.accessor((row) => row?.personals?.lastName, {
    id: "personals.lastName",
    cell: (props) => (
      <RowEditDialog
        row={props.cell.row.original}
        value={props.cell.getValue()}
      />
    ),
    header: () => (
      <span className="text-sm">{t("table_person_header_lastname")}</span>
    ),
  }),
  columnHelper.accessor((row) => row?.personals?.firstName, {
    id: "personals.firstName",
    cell: (props) => (
      <RowEditDialog
        row={props.cell.row.original}
        value={props.cell.getValue()}
      />
    ),
    header: () => (
      <span className="text-sm">{t("table_person_header_firstname")}</span>
    ),
  }),
  columnHelper.accessor((row) => row?.employment?.company?.name, {
    id: "employment.company.name",
    cell: (props) => (
      <RowEditDialog
        row={props.cell.row.original}
        value={props.cell.getValue()}
      />
    ),
    header: () => (
      <span className="text-sm">{t("table_person_header_companyname")}</span>
    ),
  }),
];

interface Props {
  data: PeopleT | null;
  isLoading: boolean;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  defaultData: never[];
  setSort: Dispatch<SetStateAction<SortState>>;
  sort: {
    sortName: string;
    sortValue: string;
  };
}
const PersonTableMobile = ({
  data,
  isLoading,
  setPagination,
  pagination,
  defaultData,
  setSort,
  sort,
}: Props) => {
  const table = useReactTable({
    data: data?.content ?? defaultData,
    columns,
    pageCount: data?.totalPages ?? -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  if (!data && isLoading) {
    return (
      <div className="fixed inset-0 z-20 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-20 top-0 left-0">
        <Spinner customClass="fill-theme-person" size="h-20 w-20" />
      </div>
    );
  }

  if (!data?.content.length && !isLoading) {
    return <EmptyResults />;
  }

  return (
    <div className="relative py-2 px-4 w-full flex justify-between flex-col">
      <MainTable
        table={table}
        setSort={setSort}
        sort={sort}
        sizesPage={[50, 100, 200]}
      />
      {isLoading ? (
        <div className="fixed inset-0 z-20 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-10 top-0 left-0">
          <Spinner customClass="fill-theme-person" size="h-20 w-20" />
        </div>
      ) : null}
    </div>
  );
};

export default PersonTableMobile;
