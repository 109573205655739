import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";

interface DropdownMenuProps {
  button: JSX.Element;
  children: JSX.Element;
  customClass?: string;
  sideOffset?: number;
}

const Dropdown = ({
  button,
  children,
  customClass,
  sideOffset = 5,
}: DropdownMenuProps) => {
  return (
    <div className="relative inline-block text-left">
      <DropdownMenuPrimitive.Root>
        <DropdownMenuPrimitive.Trigger asChild>
          {button}
        </DropdownMenuPrimitive.Trigger>

        <DropdownMenuPrimitive.Portal>
          <DropdownMenuPrimitive.Content
            align="center"
            sideOffset={sideOffset}
            className={clsx(
              "radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down z-20",
              "w-fit rounded-lg py-1 shadow-md",
              "bg-white",
              `${customClass}`
            )}
          >
            {children}
          </DropdownMenuPrimitive.Content>
        </DropdownMenuPrimitive.Portal>
      </DropdownMenuPrimitive.Root>
    </div>
  );
};

export default Dropdown;
