import { Dispatch, SetStateAction, useState } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import { Link2Icon, TrashIcon } from "@radix-ui/react-icons";
import { SortState } from "../../../types/global";
import {
  CompaniesT,
  Content as CompaniesContent,
} from "../../../types/Companies";
import Spinner from "../../common/Spinner/Spinner";
import { t } from "i18next";
import MainTable from "../../common/MainTable/MainTable";
import { AlertDialog } from "../../common/AlertDialog/AlertDialog";
import FormButton from "../../common/FormComponents/FormButton";
import DeleteDialog from "../../common/AlertDialog/Dialogs/DeleteDialog";
import { Content } from "../../../types/ContactLists";
import { Dialog } from "../../common/Dialog/Dialog";
import EmptyResults from "../../common/EmptyState/EmptyResults";
import ErrorBoundary from "../../common/ErrorBoundary";
import useUserHook from "../../../Context/UseUserContext";

interface RowActionsProps {
  id: string;
  name: string;
  department: string;
}

const columnHelper = createColumnHelper<CompaniesContent>();

const RowActions = ({ id, name, department }: RowActionsProps) => {
  const [isOpen, setIsOpen] = useState(false);
const useUser = useUserHook();
  return (
    <div className="flex flex-row justify-end gap-2">
      {useUser?.user?.role !== 'READER' && (useUser?.user?.role !== 'EDITOR' || useUser?.user?.department === department) &&  <AlertDialog
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        button={
          <FormButton
            customClass="h-6 w-6"
            theme={{
              borderColor: "border-red-600",
              bgColor: "",
              textColor: "",
            }}
          >
            <TrashIcon className="w-3 h-3 text-red-600" />
          </FormButton>
        }
      >
        <DeleteDialog
          formType="companies"
          id={id}
          setIsOpen={setIsOpen}
          message={`${t("delete_dialog_company_1")} ${name} ${t(
            "delete_dialog_company_2"
          )}`}
        />
      </AlertDialog>}
    </div>
  );
};

interface RowEditDialogProps {
  row: CompaniesContent;
  button: JSX.Element;
}

const RowEditDialog = ({ row, button }: RowEditDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      button={button}
      formType="company"
      edit={row}
    />
  );
};

const columns = [
  columnHelper.accessor((row) => row?.name, {
    id: "name",
    cell: (props) => (
      <RowEditDialog
        row={props.cell.row.original}
        button={
          props.cell.getValue().length > 0 ? (
            <span className="cursor-pointer">{props.cell.getValue()}</span>
          ) : (
            <span className="cursor-pointer italic">
              {props.cell.row.original.mainEmail}
            </span>
          )
        }
      />
    ),
    header: () => <span>{t("table_company_header_name")}</span>,
  }),
  columnHelper.accessor((row) => row?.branch, {
    id: "branch",
    cell: (props) => (
      <RowEditDialog
        row={props.cell.row.original}
        button={<span className="cursor-pointer">{props.cell.getValue()}</span>}
      />
    ),
    header: () => <span>{t("table_company_header_branch")}</span>,
  }),
  columnHelper.accessor((row) => row?.people?.length.toString(), {
    id: "people_length",
    cell: (props) => (
      <RowEditDialog
        row={props.cell.row.original}
        button={<span className="cursor-pointer">{props.cell.getValue()}</span>}
      />
    ),
    header: () => <span>{t("table_company_header_numberpeople")}</span>,
  }),
  columnHelper.accessor((row) => row?.homepage, {
    id: "homepage",
    header: () => <span>{t("table_company_header_homepage")}</span>,
    cell: (props) => (
      <a
        href={props.cell.getValue()}
        target="_blank"
        rel="noreferrer"
        className="cursor-pointer flex gap-2 items-center"
      >
        {props.cell.getValue() && (
          <Link2Icon className="text-gray-400" width={18} height={18} />
        )}
        {props.cell.getValue()}
      </a>
    ),
  }),
  columnHelper.display({
    id: "actions",
    header: () => (
      <span className="text-right">
        {t("table_company_header_quickaction")}
      </span>
    ),
    cell: (props) => (
      <RowActions
        id={props.cell.row.original.id}
        name={props.cell.row.original.name}
        department={props?.cell?.row?.original?.department}
      />
    ),
  }),
];

interface Props {
  data: CompaniesT | null;
  isLoading: boolean;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  defaultData: never[];
  setSort: Dispatch<SetStateAction<SortState>>;
  sort: {
    sortName: string;
    sortValue: string;
  };
}
const CompanyTable = ({
  data,
  isLoading,
  setPagination,
  pagination,
  defaultData,
  setSort,
  sort,
}: Props) => {
  const table = useReactTable({
    data: data?.content ?? defaultData,
    columns,
    pageCount: data?.totalPages ?? -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  if (!data && isLoading) {
    return (
      <div className="fixed inset-0 z-20 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-20 top-0 left-0">
        <Spinner customClass="fill-theme-company" size="h-20 w-20" />
      </div>
    );
  }

  if (!data?.content.length && !isLoading) {
    return <EmptyResults />;
  }

  return (
    <div className="relative py-2 px-12 w-full flex justify-between flex-col">
      <ErrorBoundary>
        <MainTable
          table={table}
          setSort={setSort}
          sort={sort}
          sizesPage={[50, 100, 200]}
        />
      </ErrorBoundary>
      {isLoading ? (
        <div className="fixed inset-0 z-20 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-10 top-0 left-0">
          <Spinner customClass="fill-theme-company" size="h-20 w-20" />
        </div>
      ) : null}
    </div>
  );
};

export default CompanyTable;
