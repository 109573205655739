import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";

import Spinner from "../../common/Spinner/Spinner";
import { TagsT, Content } from "../../../types/Tags";
import { t } from "i18next";
import MainTable from "../../common/MainTable/MainTable";
import { Dispatch, SetStateAction, useState } from "react";
import { SortState } from "../../../types/global";
import { AlertDialog } from "../../common/AlertDialog/AlertDialog";

import EmptyResults from "../../common/EmptyState/EmptyResults";
import EditTagsDialog from "../../common/AlertDialog/Dialogs/EditTagsDialog";



const columnHelper = createColumnHelper<Content>();

interface RowEditDialogProps {
  oldTag: string;
}

const RowEditDialog = ({ oldTag }: RowEditDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <AlertDialog
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      button={<button type="button" className="break-all break-all">{oldTag}</button>}
    >
      <EditTagsDialog id={encodeURI(oldTag)} setIsOpen={setIsOpen} />
    </AlertDialog>
  );
};

const columns = [
  columnHelper.accessor((row) => row?.value, {
    id: "value",
    cell: (props) => <RowEditDialog oldTag={props.cell.getValue()} />,
    header: () => <span>{t("table_distributor_header_name")}</span>,
  })
];

interface Props {
  data: TagsT | null;
  isLoading: boolean;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  defaultData: never[];
  setSort: Dispatch<SetStateAction<SortState>>;
  sort: {
    sortName: string;
    sortValue: string;
  };
}
const TagsTableMobile = ({
  data,
  isLoading,
  setPagination,
  pagination,
  defaultData,
  setSort,
  sort,
}: Props) => {
  const table = useReactTable({
    data: data?.content ?? defaultData,
    columns,
    pageCount: data?.totalPages ?? -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  if (!data && isLoading) {
    return (
      <div className="fixed inset-0 z-20 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-20 top-0 left-0">
        <Spinner customClass="fill-theme-distributor" size="h-20 w-20" />
      </div>
    );
  }

  if (!data?.content.length && !isLoading) {
    return <EmptyResults />;
  }

  return (
    <div className="relative py-2 px-4 w-full flex justify-between flex-col">
      <MainTable
        table={table}
        setSort={setSort}
        sort={sort}
        sizesPage={[50, 100, 200]}
      />
      {isLoading ? (
        <div className="fixed inset-0 z-20 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-10 top-0 left-0">
          <Spinner customClass="fill-theme-distributor" size="h-20 w-20" />
        </div>
      ) : null}
    </div>
  );
};

export default TagsTableMobile;
