import { Component } from "react";
import ErrorDialog from "./AlertDialog/Dialogs/ErrorDialog";
import { AlertDialog } from "./AlertDialog/AlertDialog";
import { t } from "i18next";
import * as Sentry from '@sentry/react';
import {AxiosError} from "axios";

interface Props {
  children: JSX.Element;
  fallback?: JSX.Element;
}

interface State {
  hasError: boolean;
  isOpen: boolean;
  text: string;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      isOpen: false,
      text: ''
    };
    this.setIsOpen = this.setIsOpen.bind(this);
  }

  setIsOpen(value: boolean) {
    this.setState({
      isOpen: value,
    });
  }

  componentDidCatch(error: Error, info: any) {
    if(error instanceof AxiosError) { // take error message from bad requests
      const status = error.response?.status;
      if(status === 400) {
        const {message} = error.response?.data;
        this.setState({hasError: true, isOpen:true, text:message});
        return;
      }
    }

    Sentry.captureException(error);
    this.setState({ hasError: true, isOpen: true, text: '' });
  }

  render() {
    const { fallback, children } = this.props;
    const { hasError, isOpen } = this.state;
    const {text} = this.state;

    if (hasError) {
      return (
        <>
          <AlertDialog
            key="error-dialog"
            setIsOpen={this.setIsOpen as any}
            isOpen={isOpen}
            button={<></>}
          >
            <ErrorDialog
              setIsOpen={this.setIsOpen as any}
              message={text && text !== ''? text : t("error_dialog_message")}
            />
          </AlertDialog>
          {fallback}
        </>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
