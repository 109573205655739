import { useState } from "react";
import { Switch } from "@headlessui/react";

interface Props {
  name: string;
  label: string;
  formType: "person" | "company";
}
const SwitchForm = ({ name, label, formType }: Props) => {
  const [enabled, setEnabled] = useState(true);

  return (
    <div className={`flex flex-row gap-4 justify-between items-center`}>
      <label className="flex flex-row items-start md:text-base text-sm pb-2 gap-1">
        {label}
      </label>
      <Switch
        name={name}
        checked={enabled}
        onChange={setEnabled}
        className={`${
          enabled
            ? formType === "company"
              ? "bg-theme-company"
              : "bg-theme-person"
            : "bg-slate-400"
        } relative inline-flex h-6 w-11 items-center rounded-full shrink-0`}
      >
        <span
          className={`${
            enabled ? "translate-x-6" : "translate-x-1"
          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </Switch>
    </div>
  );
};

export default SwitchForm;
