import * as CollapsiblePrimitive from "@radix-ui/react-collapsible";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface CollapsibleProps {
  children: JSX.Element;
  button: JSX.Element;
  setTagsFilters?: Dispatch<SetStateAction<string[]>>;
  setCompanyFilters?: Dispatch<SetStateAction<string>>;
  fullWidth?: boolean;
}

const Collapsible = ({
  children,
  button,
  setCompanyFilters,
  setTagsFilters,
  fullWidth,
}: CollapsibleProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (setCompanyFilters) setCompanyFilters("");
    if (setTagsFilters) {
      setTagsFilters([]);
    }
  }, [isOpen, setCompanyFilters, setTagsFilters]);

  return (
    <CollapsiblePrimitive.Root
      open={isOpen}
      onOpenChange={setIsOpen}
      className={`flex flex-col justify-end ${fullWidth && "w-full"}`}
    >
      <CollapsiblePrimitive.Trigger className="flex justify-end">
        {button}
      </CollapsiblePrimitive.Trigger>
      <CollapsiblePrimitive.Content className="flex flex-row w-full">
        {children}
      </CollapsiblePrimitive.Content>
    </CollapsiblePrimitive.Root>
  );
};

export { Collapsible };
