import { ChevronDownIcon, Cross2Icon, ResetIcon } from "@radix-ui/react-icons";
import axios from "axios";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Content } from "../../../../types/Companies";
import Dropdown from "../../Dropdown/Dropdown";
import FormInputTags from "../../FormComponents/FormInputTags";
import { Item } from "../../FormComponents/SelectForm/SelectForm";
import CompanyComboBox from "../../FormComponents/CompanyComboBox";
import { Tooltip } from "../../Tooltip/Tooltip";
import useDeviceType from "../../../../Context/useDeviceType";

interface Props {
  formType: "person" | "company" | "distributor";
  setTagsFilters?: Dispatch<SetStateAction<string[]>>;
  setCompanyFilters?: Dispatch<SetStateAction<string>>;
  setTagsTypeFilter?: Dispatch<SetStateAction<"AND" | "OR" | "NONE">>;
  tagsTypeFilter?: "AND" | "OR" | "NONE";
}

const Filters = ({
  formType,
  setCompanyFilters,
  setTagsFilters,
  setTagsTypeFilter,
  tagsTypeFilter,
}: Props) => {
  const [query, setQuery] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [companies, setCompanies] = useState<Item[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<Item | null>(null);
  const device = useDeviceType();

  const { t } = useTranslation();

  const handleDeleteTag = useCallback(
    (selectedTag: string) => {
      setTags(tags.filter((tag) => tag !== selectedTag));
    },
    [tags]
  );

  const handleAdditionTag = useCallback(
    (tag: string) => {
      if (!tags.find((el) => el === tag)) {
        setTags([...tags, tag]);
      }
    },
    [tags]
  );

  const handleResetFilters = useCallback(() => {
    setTags([]);
    setSelectedCompany(null);
    setQuery("");
    setTagsTypeFilter && setTagsTypeFilter("AND");
  }, [setTagsTypeFilter]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_DOMAIN}/api/v1/companies`, {
        params: {
          page: 0,
          size: 5,
          sort: `name,asc`,
          search: query,
        },
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setCompanies(
          response.data.content.map((element: Content) => {
            return {
              id: element.id,
              name: element.name === "" ? element.mainEmail : element.name,
            };
          })
        );
      })
      .catch((error) => console.log(error));
  }, [query]);

  useEffect(() => {
    if (tags && setTagsFilters) {
      setTagsFilters(tags);
    }
  }, [setTagsFilters, tags]);

  useEffect(() => {
    if (setCompanyFilters) {
      if (selectedCompany) {
        setCompanyFilters(selectedCompany.id);
      } else {
        setCompanyFilters("");
      }
    }
  }, [selectedCompany, setCompanyFilters]);

  return (
    <div className="flex flex-col md:flex-row w-full gap-4">
      <div className="md:min-w-[350px] md:max-w-[350px] w-full">
        {formType === "person" && (
          <CompanyComboBox
            items={companies}
            query={query}
            setQuery={setQuery}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
          />
        )}
      </div>
      <div className="flex flex-col w-full break-all md:min-w-[350px] md:max-w-[350px]">
        <div className="flex gap-2">
          <FormInputTags
            id="keywords"
            name="keywords"
            label={t("form_person_data_label_keywords")}
            placeholder={t("form_person_data_keywords_placeholder") ?? ""}
            handleAdditionTag={handleAdditionTag}
            marked={false}
          />
          <Dropdown
            button={
              <button className="flex gap-1 items-center rounded-lg border-[1px] border-theme-distributor mt-8 px-2 w-max py-1 capitalize">
                {tagsTypeFilter?.toLocaleLowerCase()}
                <ChevronDownIcon />
              </button>
            }
            // sideOffset={device !== "Desktop" ? -50 : 5}
            children={
              <div className="flex flex-col gap-4 max-w-[250px] md:text-base text-xs">
                <button
                  className={`text-left hover:bg-gray-100 p-4 ${
                    tagsTypeFilter === "AND" && "bg-slate-100"
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setTagsTypeFilter && setTagsTypeFilter("AND");
                  }}
                >
                  <p className="font-bold">{t("tag_filter_and_title")}</p>
                  <p>{t("tag_filter_and")}</p>
                </button>
                <button
                  className={`text-left hover:bg-gray-100 p-4 ${
                    tagsTypeFilter === "OR" && "bg-slate-100"
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setTagsTypeFilter && setTagsTypeFilter("OR");
                  }}
                >
                  <p className="font-bold">{t("tag_filter_or_title")}</p>
                  <p>{t("tag_filter_or")}</p>
                </button>
                <button
                  className={`text-left hover:bg-gray-100 p-4 ${
                    tagsTypeFilter === "NONE" && "bg-slate-100"
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setTagsTypeFilter && setTagsTypeFilter("NONE");
                  }}
                >
                  <p className="font-bold">{t("tag_filter_none_title")}</p>
                  <p>{t("tag_filter_none")}</p>
                </button>
              </div>
            }
          />
        </div>
        <div className="flex flex-row gap-2 flex-wrap py-4">
          {tags.sort().map((tag) => (
            <div
              className="flex flex-row items-center gap-4 bg-black rounded-2xl text-white w-fit px-3 py-1"
              key={tag}
            >
              #{tag}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteTag(tag);
                }}
              >
                <Cross2Icon />
              </button>
            </div>
          ))}
        </div>
      </div>
      <div>
        <Tooltip
          message={t("filters-reset")}
          button={
            <button
              className="border-[2px] font-bold rounded-full p-1 mt-7"
              onClick={() => handleResetFilters()}
            >
              <ResetIcon width={24} height={24} />
            </button>
          }
        />
      </div>
    </div>
  );
};

export { Filters };
