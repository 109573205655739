import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./i18n/config";

import Home from "./routes/home";
import ErrorPage from "./routes/error-page";
import Person from "./routes/person";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Distributor from "./routes/distributor";
import Company from "./routes/company";
import { TableProvider } from "./Context/TableContext";
import { FormNavigationProvider } from "./Context/FormNavigationContext";
import { RequestErrorContextProvider } from "./Context/RequestErrorContext";
import { HashRouter, Route, Routes } from "react-router-dom";
import { Auth0ProviderWithNavigate } from "./components/Login/LoginProvider";
import { AuthenticationGuard } from "./components/Login/AuthenticationGuard";
import Users from "./routes/users";
import { UserProvider } from "./Context/UserContext";
import Tags from "./routes/tags";
import * as Sentry from '@sentry/react';

Sentry.init({
    dsn: "https://9cfe7c97396a793f5b855430e06a5d1a@o910996.ingest.sentry.io/4505685548204032"
});

const HomeWrapper = () => {
  return (
    <>
      <title>Conea - Home</title>
      <Header />
      <Home />
      <Footer />
    </>
  );
};

const PersonWrapper = () => {
  return (
    <>
      <title>Conea - Personen</title>
      <Header />
      <FormNavigationProvider>
        <Person />
      </FormNavigationProvider>
      <Footer />
    </>
  );
};

const CompanyWrapper = () => {
  return (
    <>
      <title>Conea - Medien</title>
      <Header />
      <FormNavigationProvider>
        <Company />
      </FormNavigationProvider>
      <Footer />
    </>
  );
};

const DistributorWrapper = () => {
  return (
    <>
      <title>Conea - Verteiler</title>
      <Header />
      <FormNavigationProvider>
        <Distributor />
      </FormNavigationProvider>
      <Footer />
    </>
  );
};

const UsersWrapper = () => {
  return (
    <>
      <title>Conea - Benutzerverwaltung</title>
      <Header />
      <FormNavigationProvider>
        <Users />
      </FormNavigationProvider>
      <Footer />
    </>
  );
};

const TagsWrapper = () => {
  return (
    <>
      <title>Conea - Tags</title>
      <Header />
      <FormNavigationProvider>
        <Tags />
      </FormNavigationProvider>
      <Footer />
    </>
  );
};
const routes = [
  {
    path: "/",
    element: <AuthenticationGuard component={HomeWrapper} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "person",
    element: <AuthenticationGuard component={PersonWrapper} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "company",
    element: <AuthenticationGuard component={CompanyWrapper} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "distributor",
    element: <AuthenticationGuard component={DistributorWrapper} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "users",
    element: <AuthenticationGuard component={UsersWrapper} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "tags",
    element: <AuthenticationGuard component={TagsWrapper} />,
    errorElement: <ErrorPage />,
  },
];

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <HashRouter>
      <Auth0ProviderWithNavigate>
        <UserProvider>
          <RequestErrorContextProvider>
            <TableProvider>
              <Routes>
                {routes.map((el) => (
                  <Route
                    key={el.path}
                    path={el.path}
                    element={el.element}
                    errorElement={el.errorElement}
                  />
                ))}
              </Routes>
            </TableProvider>
          </RequestErrorContextProvider>
        </UserProvider>
      </Auth0ProviderWithNavigate>
    </HashRouter>
  </React.StrictMode>
);
