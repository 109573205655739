import { useTranslation } from "react-i18next";
import DropZone from "./DropZone";
import FiledDocuments from "./FiledDocuments";
import docFile from "../../../../assets/doc-file.png";
import pdfFile from "../../../../assets/pdf-file.png";
import { Item, SelectForm } from "../SelectForm/SelectForm";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Content } from "../../../../types/ContactLists";
import axios from "axios";
import { Membership } from "../../../../types/People";
import useUserHook from "../../../../Context/UseUserContext";
import FormComboBox from "../ComboBox/FormComboBox";
import { GDPR } from "../../../../mocks/GDPR";
import { ComboBoxT } from "../../../../types/global";

interface Props {
  memberships: Membership[];
  selectedMembership: Membership | undefined;
  setSelectedMembership: (selectedMembership: Membership | undefined) => void;
  consentDate: string;
  setConsentDate: (consentDate: string) => void;
  attachments: File[] | null;
  setAttachments: (attachments: File[] | null) => void;
  setSelectedContactLists: (selectedContactLists: Item | null) => void;
  selectedContactLists: Item | null;
  setSelectedGdpr: Dispatch<SetStateAction<ComboBoxT | null>>;
  selectedGdpr: ComboBoxT | null;
}

const Memberships = ({
  memberships,
  attachments,
  consentDate,
  selectedMembership,
  setAttachments,
  setConsentDate,
  setSelectedMembership,
  setSelectedContactLists,
  selectedContactLists,
  setSelectedGdpr,
  selectedGdpr,
}: Props) => {
  const { t } = useTranslation();
  const useUser = useUserHook();

  const [contactLists, setContactLists] = useState<Item[] | null>(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_DOMAIN}/api/v1/contactLists`, {
        params: {
          page: 0,
          size: 99999,
          sort: `name,asc`,
        },
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setContactLists(
          response.data.content.map((element: Content) => {
            return { id: element.id, name: element.name };
          })
        );
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (selectedContactLists) {
      const selected = memberships?.find(
        (el) => el.list.id === selectedContactLists.id
      );
      if (selected) {
        setSelectedMembership(selected);
        setSelectedGdpr(
          GDPR.find((find) => find.id === selected?.gdprOptIn) ?? null
        );
        setConsentDate(selected.consentDate ?? "");
      } else {
        setSelectedMembership(undefined);
        setConsentDate("");
        setSelectedGdpr(null);
      }
    } else {
      setSelectedMembership(undefined);
      setConsentDate("");
      setSelectedGdpr(null);
    }
  }, [
    memberships,
    selectedContactLists,
    setConsentDate,
    setSelectedGdpr,
    setSelectedMembership,
  ]);

  useEffect(() => {
    if (selectedMembership) {
      setConsentDate(selectedMembership.consentDate);
    }
  }, [selectedMembership, setConsentDate]);

  return (
    <div className="flex flex-wrap md:flex-nowrap justify-between gap-6">
      <div className="flex flex-col w-full gap-6">
        <SelectForm
          name="contactList"
          label={t("membership_label_contactList")}
          doNotMark
          items={contactLists ?? []}
          setValue={setSelectedContactLists}
          value={selectedContactLists}
          disabled={useUser?.user?.role === "READER"}
        />
        <FormComboBox
          label={t("gdpr_label") ?? ""}
          fields={GDPR}
          selectedField={selectedGdpr}
          setSelectedfield={setSelectedGdpr}
          fullWidth
        />
        <div className="flex flex-col">
          <label className="flex flex-row items-start text-base font-bold pb-2 gap-1">
            {t("membership_label_consentDate")}
          </label>
          <input
            className="w-full py-1 px-1 pl-4 border-[1px] border-theme-distributor rounded-lg"
            type="date"
            value={consentDate}
            onChange={(e) => setConsentDate(e.currentTarget.value)}
            disabled={useUser?.user?.role === "READER"}
          />
        </div>
      </div>
      <div className="flex flex-col w-full">
        <label className="flex flex-row items-start text-base font-bold pb-2 gap-1">
          {t("membership_label_documents")}
        </label>
        <DropZone
          disabled={!selectedMembership ? true : false}
          setAttachments={setAttachments}
          attachments={attachments}
        />
      </div>
      <div className="flex flex-col w-full gap-2">
        <label className="flex flex-row items-start text-base font-bold pb-2 gap-1">
          {t("membership_label_filedDocuments")}
        </label>
        {selectedMembership &&
          selectedMembership?.attachments?.map((el) => (
            <FiledDocuments
              name={el.fileName}
              icon={el.mimeType.includes("pdf") ? pdfFile : docFile}
              id={el.id}
              key={el.id}
            />
          ))}
      </div>
    </div>
  );
};

export default Memberships;
