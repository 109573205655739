import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";

import Spinner from "../../common/Spinner/Spinner";
import { t } from "i18next";
import MainTable from "../../common/MainTable/MainTable";
import { Dispatch, SetStateAction } from "react";
import { SortState } from "../../../types/global";

import EmptyResults from "../../common/EmptyState/EmptyResults";
import { Content, UsersT } from "../../../types/Users";

const columnHelper = createColumnHelper<Content>();

interface Props {
  data: UsersT | null;
  isLoading: boolean;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  defaultData: never[];
  setSort: Dispatch<SetStateAction<SortState>>;
  sort: {
    sortName: string;
    sortValue: string;
  };
}
const UsersTableMobile = ({
  data,
  isLoading,
  setPagination,
  pagination,
  defaultData,
  setSort,
  sort,
}: Props) => {
  const columns = [
    columnHelper.accessor((row) => row?.email, {
      id: "email",
      cell: (props) => (
        <span className="cursor-pointer break-all">
          {props.cell.getValue()}
        </span>
      ),
      header: () => <span>{t("table_users_header_email")}</span>,
    }),
    columnHelper.accessor((row) => row?.state, {
      id: "state",
      cell: (props) => (
        <span className="cursor-pointer break-all">
          {props.cell.getValue()}
        </span>
      ),
      header: () => <span>{t("table_users_header_state")}</span>,
    }),
  ];

  const table = useReactTable({
    data: data?.content ?? defaultData,
    columns,
    pageCount: data?.totalPages ?? -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  if (!data && isLoading) {
    return (
      <div className="fixed inset-0 z-20 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-20 top-0 left-0">
        <Spinner customClass="fill-theme-distributor" size="h-20 w-20" />
      </div>
    );
  }

  if (!data?.content.length && !isLoading) {
    return <EmptyResults />;
  }

  return (
    <div className="relative py-2 px-4 w-full flex justify-between flex-col">
      <MainTable
        table={table}
        setSort={setSort}
        sort={sort}
        sizesPage={[10, 20, 30, 40, 50]}
      />
      {isLoading ? (
        <div className="fixed inset-0 z-20 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-10 top-0 left-0">
          <Spinner customClass="fill-theme-distributor" size="h-20 w-20" />
        </div>
      ) : null}
    </div>
  );
};

export default UsersTableMobile;
