import { PaginationState } from "@tanstack/table-core";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import SearchInput from "../components/common/Search/SearchInput";
import TableHeader from "../components/common/TableHeader/TableHeader";
import { SortState } from "../components/main/person/PersonTable";
import TagsTable from "../components/main/tags/TagsTable";
import TagsTableMobile from "../components/main/tags/TagsTableMobile";
import { ProgressProvider } from "../Context/ProgressContext";
import useDeviceType from "../Context/useDeviceType";
import useTableHook from "../Context/UseTableContext";
import useUserHook from "../Context/UseUserContext";
import { TagsT } from "../types/Tags";
import ErrorBoundary from "../components/common/ErrorBoundary";

const Tags = () => {
  const [openCreate, setOpenCreate] = useState(false);

  const { t } = useTranslation();
  const useUser = useUserHook();
    const [data, setData] = useState<TagsT | null>(null);
  const [searchParam, setSearchParam] = useState("");

  const useTable = useTableHook();

  const device = useDeviceType();

  const [isLoading, setIsLoading] = useState(false);

  const [{ sortName, sortValue }, setSort] = useState<SortState>({
    sortName: "value",
    sortValue: "asc",
  });

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });

  const defaultData = useMemo(() => [], []);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const sort = useMemo(
    () => ({
      sortName,
      sortValue,
    }),
    [sortName, sortValue]
  );

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_DOMAIN}/api/v1/tags`, {
        params: {
          page: pageIndex,
          size: pageSize,
          sort: `${sortName},${sortValue}`,
          search: searchParam,
        },
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [
    pageIndex,
    pageSize,
    sortName,
    sortValue,
    openCreate,
    searchParam,
    useTable?.refresh,
  ]);

  if (useUser?.user?.role !== "ADMIN" && useUser?.user?.role !== "OWNER") {
    Navigate({ to: "/" });
  }

  return (
    <div className="m-auto w-[95vw] py-4 md:py-12 flex flex-col xl:max-w-[1200px] 2xl:max-w-[1640px] min-h-[816px] mt-28 md:my-8 bg-white shadow-theme rounded-lg border-t-8 border-theme-tags md:gap-7">
      <h1 className="text-theme-tags font-bold 2xl:text-4xl text-2xl mb-4 md:mb-6 px-4 md:px-12">
        {t("tags_title")}
      </h1>
      <div className="flex flex-row flex-wrap md:flex-nowrap justify-between w-full gap-4 md:gap-10 px-4 md:px-12 md:mb-0 mb-4">
        <ErrorBoundary>
          <SearchInput
            id="searchParamtags"
            name="searchParamtags"
            placeholder={t("table_search")}
            setSearchParam={setSearchParam}
          />
        </ErrorBoundary>
      </div>
      <div className="w-full border-t-[1px] border-divisor pt-4">
        <ErrorBoundary>
          <TableHeader
            title={t("table_title_tags")}
            formType="tags"
            openCreate={openCreate}
            setOpenCreate={setOpenCreate}
            search={searchParam}
          />
        </ErrorBoundary>
        <ProgressProvider>
          {device === "Phone" ? (
            <TagsTableMobile
              data={data}
              defaultData={defaultData}
              isLoading={isLoading}
              pagination={pagination}
              setPagination={setPagination}
              setSort={setSort}
              sort={sort}
            />
          ) : (
            <TagsTable
              data={data}
              defaultData={defaultData}
              isLoading={isLoading}
              pagination={pagination}
              setPagination={setPagination}
              setSort={setSort}
              sort={sort}
            />
          )}
        </ProgressProvider>
      </div>
    </div>
  );
};

export default Tags;
