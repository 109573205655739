import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import useRequestErrorContextHook from "../../../../Context/UseRequestErrorContext";

interface Props {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  message: string;
}
const RequestErrorDialog = ({ setIsOpen, message }: Props) => {
  const { t } = useTranslation();

  const useErrorDialog = useRequestErrorContextHook();

  return (
    <div className="flex flex-row flex-wrap gap-8 px-6 py-4">
      <div className="flex flex-col items-center md:justify-start justify-center md:w-auto w-full mt-4">
        <ExclamationTriangleIcon className="w-24 h-24 md:w-16 md:h-16" />
      </div>
      <div className="flex flex-col gap-4">
        <h1 className="font-bold">{t("delete_dialog_header")}</h1>
        <p>{message}</p>
        <div className="flex flex-row gap-4">
          <button
            className="border-[1px] border-black bg-white rounded-full text-black font-bold px-2 py-1 h-full self-end min-w-[137px] disabled:text-gray-300 disabled:border-gray-300"
            onClick={() => {
              setIsOpen(false);
              useErrorDialog?.setRetry(true);
            }}
          >
            {t("requestError_dialog_gray_button")}
          </button>
          <button
            onClick={() => {
              setIsOpen(false);
            }}
            className="border-[1px] border-[#FF2B4B] bg-[#FF2B4B] rounded-full text-white font-bold px-2 py-1 h-full self-end min-w-[137px] disabled:text-gray-300 disabled:border-gray-300"
          >
            {t("requestError_dialog_black_button")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestErrorDialog;
