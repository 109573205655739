import { useAuth0 } from "@auth0/auth0-react";
import { ExitIcon } from "@radix-ui/react-icons";

interface Props {
  customClass?: string;
  icon?: JSX.Element;
}
const LogoutButton = ({ customClass, icon = <ExitIcon /> }: Props) => {
  const { logout } = useAuth0();

  return (
    <button
      className={`flex gap-2 cursor-pointer select-none items-center rounded-md px-4 py-2 outline-none text-gray-700 focus:bg-gray-50 ${customClass}`}
      onClick={() =>
        logout({ logoutParams: { returnTo: window.location.origin } })
      }
    >
      {icon}
      Logout
    </button>
  );
};

export default LogoutButton;
