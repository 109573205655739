import {
  DownloadIcon,
  MixerHorizontalIcon,
  PlusIcon,
} from "@radix-ui/react-icons";
import axios from "axios";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useDeviceType from "../../../Context/useDeviceType";
import useUserHook from "../../../Context/UseUserContext";
import { AlertDialog } from "../AlertDialog/AlertDialog";
import CreateTagsDialog from "../AlertDialog/Dialogs/CreateTagsDialog";
import CreateUserDialog from "../AlertDialog/Dialogs/CreateUserDialog";
import { Collapsible } from "../Collapsible/Collapsible";
import { Dialog } from "../Dialog/Dialog";
import { Tooltip } from "../Tooltip/Tooltip";
import { Filters } from "./Filters/Filters";
import { UserFilters } from "./Filters/UserFilters";
import ExportDialog from "../AlertDialog/Dialogs/ExportDialog/ExportDialog";
import NormalDialog from "../AlertDialog/NormalDialog";

interface Props {
  title: string;
  formType: "person" | "company" | "distributor" | "user" | "tags";
  openCreate: boolean;
  setOpenCreate: Dispatch<SetStateAction<boolean>>;
  search: string;
  tagsFilter?: string[];
  companyIdFilter?: string;
  quickFilter?: string;
  setTagsFilters?: Dispatch<SetStateAction<string[]>>;
  setCompanyFilters?: Dispatch<SetStateAction<string>>;
  setStatusFilter?: Dispatch<SetStateAction<string>>;
  setTagsTypeFilter?: Dispatch<SetStateAction<"AND" | "OR" | "NONE">>;
  tagsTypeFilter?: "AND" | "OR" | "NONE";
}

const TableHeader = ({
  title,
  formType,
  openCreate,
  setOpenCreate,
  search,
  tagsFilter,
  companyIdFilter,
  quickFilter,
  setCompanyFilters,
  setTagsFilters,
  setStatusFilter,
  setTagsTypeFilter,
  tagsTypeFilter,
}: Props) => {
  const { t } = useTranslation();
  const useUser = useUserHook();
  const device = useDeviceType();

  const [isOpenExportDialog, setIsOpenExportDialog] = useState(false);

  const handleCsv = useCallback(
    (selectedFields: string) => {
      axios
        .get(
          `${process.env.REACT_APP_DOMAIN}/api/v1/${
            formType === "company" ? "companies" : "people"
          }/all`,
          {
            params: {
              search: search,
              tags: tagsFilter?.join(),
              companyIds: companyIdFilter ?? "",
              quickFilter: quickFilter ?? "",
              select: selectedFields,
            },
            headers: {
              Accept: "text/csv",
              "Access-Control-Allow-Origin": "*",
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "text/csv;charset=utf-8",
          });

          if (navigator?.msSaveBlob) {
            // For ie and Edge
            return navigator.msSaveBlob(
              blob,
              formType === "company" ? "Companies" : "People"
            );
          } else {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = formType === "company" ? "Companies" : "People";
            document.body.appendChild(link);
            link.dispatchEvent(
              new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
              })
            );
            link.remove();
            window.URL.revokeObjectURL(link.href);
          }
        })
        .catch((error) => console.log(error));
    },
    [companyIdFilter, formType, quickFilter, search, tagsFilter]
  );

  return (
    <div className="flex md:flex-row flex-col justify-between px-4 md:px-12 md:pb-12 pb-5 pt-2 w-full">
      <h1 className="text-2xl font-bold md:inherit absolute">{title}</h1>
      <div className="flex flex-row gap-1 md:gap-4 items-start justify-end w-full">
        {formType !== "distributor" && formType !== "tags" && (
          <>
            <Collapsible
              fullWidth
              button={
                <Tooltip
                  message={t("table_title_filters_tooltip")}
                  button={
                    <button className="self-right w-9 h-9 flex items-center justify-center border-[1px] rounded-full border-theme-distributor hover:border-black text-theme-distributor hover:text-black">
                      <MixerHorizontalIcon className="w-5 h-5" />
                    </button>
                  }
                />
              }
              children={
                formType === "user" ? (
                  <UserFilters setStatusFilter={setStatusFilter} />
                ) : (
                  <Filters
                    formType={formType}
                    setTagsFilters={setTagsFilters}
                    setCompanyFilters={setCompanyFilters}
                    setTagsTypeFilter={setTagsTypeFilter}
                    tagsTypeFilter={tagsTypeFilter}
                  />
                )
              }
              setCompanyFilters={setCompanyFilters}
              setTagsFilters={setTagsFilters}
            />

            {formType !== "user" && (
              <NormalDialog
                setIsOpen={setIsOpenExportDialog}
                isOpen={isOpenExportDialog}
                button={
                  <Tooltip
                    message={t("table_title_download_tooltip")}
                    button={
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setIsOpenExportDialog(true);
                          // handleCsv();
                        }}
                        className="w-9 h-9 flex items-center justify-center border-[1px] rounded-full border-theme-distributor hover:border-black text-theme-distributor hover:text-black shrink-0"
                      >
                        <DownloadIcon className="w-5 h-5 " />
                      </button>
                    }
                  />
                }
              >
                <ExportDialog
                  setIsOpen={setIsOpenExportDialog}
                  formType={formType}
                  handleCsv={handleCsv}
                />
              </NormalDialog>
            )}

            {/* <Tooltip
              message={t("table_title_upload_tooltip")}
              button={
                <button className="w-9 h-9 flex items-center justify-center border-[1px] rounded-full border-theme-distributor hover:border-black text-theme-distributor hover:text-black">
                  <UploadIcon className="w-5 h-5 " />
                </button>
              }
            /> */}
          </>
        )}
        {useUser?.user?.role !== "READER" && (
          <>
            {formType !== "user" && formType !== "tags" ? (
              <Dialog
                isOpen={openCreate}
                setIsOpen={setOpenCreate}
                button={
                  device !== "Phone" ? (
                    <button className="border-[1px] border-[#00D975] bg-[#00D975] rounded-full text-base text-white font-bold px-8 py-1">
                      {t("table_title_button")}
                    </button>
                  ) : (
                    <button
                      className={`px-1 py-1 rounded-2xl 2xl:text-base xl:text-sm text-white bg-[#00D975] `}
                    >
                      <PlusIcon width={26} height={26} />
                    </button>
                  )
                }
                formType={formType}
                edit={null}
                independent
              />
            ) : (
              <AlertDialog
                button={
                  device !== "Phone" ? (
                    <button className="border-[1px] border-[#00D975] bg-[#00D975] rounded-full text-base text-white font-bold px-8 py-1">
                      {t("table_title_button")}
                    </button>
                  ) : (
                    <button
                      className={`px-1 py-1 rounded-2xl 2xl:text-base xl:text-sm text-white bg-[#00D975] `}
                    >
                      <PlusIcon width={26} height={26} />
                    </button>
                  )
                }
                children={
                  formType === "user" ? (
                    <CreateUserDialog setIsOpen={setOpenCreate} />
                  ) : (
                    <CreateTagsDialog setIsOpen={setOpenCreate} />
                  )
                }
                isOpen={openCreate}
                setIsOpen={setOpenCreate}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TableHeader;
