export const LANGUAGES_CODE = [
  {
    id: "aa",
    label: "Afar",
    native: "Afar",
  },
  {
    id: "ab",
    label: "Abkhazian",
    native: "Аҧсуа",
  },
  {
    id: "af",
    label: "Afrikaans",
    native: "Afrikaans",
  },
  {
    id: "ak",
    label: "Akan",
    native: "Akana",
  },
  {
    id: "am",
    label: "Amharic",
    native: "አማርኛ",
  },
  {
    id: "an",
    label: "Aragonese",
    native: "Aragonés",
  },
  {
    id: "ar",
    label: "Arabic",
    native: "العربية",
    rtl: 1,
  },
  {
    id: "as",
    label: "Assamese",
    native: "অসমীয়া",
  },
  {
    id: "av",
    label: "Avar",
    native: "Авар",
  },
  {
    id: "ay",
    label: "Aymara",
    native: "Aymar",
  },
  {
    id: "az",
    label: "Azerbaijani",
    native: "Azərbaycanca / آذربايجان",
  },
  {
    id: "ba",
    label: "Bashkir",
    native: "Башҡорт",
  },
  {
    id: "be",
    label: "Belarusian",
    native: "Беларуская",
  },
  {
    id: "bg",
    label: "Bulgarian",
    native: "Български",
  },
  {
    id: "bh",
    label: "Bihari",
    native: "भोजपुरी",
  },
  {
    id: "bi",
    label: "Bislama",
    native: "Bislama",
  },
  {
    id: "bm",
    label: "Bambara",
    native: "Bamanankan",
  },
  {
    id: "bn",
    label: "Bengali",
    native: "বাংলা",
  },
  {
    id: "bo",
    label: "Tibetan",
    native: "བོད་ཡིག / Bod skad",
  },
  {
    id: "br",
    label: "Breton",
    native: "Brezhoneg",
  },
  {
    id: "bs",
    label: "Bosnian",
    native: "Bosanski",
  },
  {
    id: "ca",
    label: "Catalan",
    native: "Català",
  },
  {
    id: "ce",
    label: "Chechen",
    native: "Нохчийн",
  },
  {
    id: "ch",
    label: "Chamorro",
    native: "Chamoru",
  },
  {
    id: "co",
    label: "Corsican",
    native: "Corsu",
  },
  {
    id: "cr",
    label: "Cree",
    native: "Nehiyaw",
  },
  {
    id: "cs",
    label: "Czech",
    native: "Česky",
  },
  {
    id: "cu",
    label: "Old Church Slavonic / Old Bulgarian",
    native: "словѣньскъ / slověnĭskŭ",
  },
  {
    id: "cv",
    label: "Chuvash",
    native: "Чăваш",
  },
  {
    id: "cy",
    label: "Welsh",
    native: "Cymraeg",
  },
  {
    id: "da",
    label: "Danish",
    native: "Dansk",
  },
  {
    id: "de",
    label: "German",
    native: "Deutsch",
  },
  {
    id: "dv",
    label: "Divehi",
    native: "ދިވެހިބަސް",
    rtl: 1,
  },
  {
    id: "dz",
    label: "Dzongkha",
    native: "ཇོང་ཁ",
  },
  {
    id: "ee",
    label: "Ewe",
    native: "Ɛʋɛ",
  },
  {
    id: "el",
    label: "Greek",
    native: "Ελληνικά",
  },
  {
    id: "en",
    label: "English",
    native: "English",
  },
  {
    id: "eo",
    label: "Esperanto",
    native: "Esperanto",
  },
  {
    id: "es",
    label: "Spanish",
    native: "Español",
  },
  {
    id: "et",
    label: "Estonian",
    native: "Eesti",
  },
  {
    id: "eu",
    label: "Basque",
    native: "Euskara",
  },
  {
    id: "fa",
    label: "Persian",
    native: "فارسی",
    rtl: 1,
  },
  {
    id: "ff",
    label: "Peul",
    native: "Fulfulde",
  },
  {
    id: "fi",
    label: "Finnish",
    native: "Suomi",
  },
  {
    id: "fj",
    label: "Fijian",
    native: "Na Vosa Vakaviti",
  },
  {
    id: "fo",
    label: "Faroese",
    native: "Føroyskt",
  },
  {
    id: "fr",
    label: "French",
    native: "Français",
  },
  {
    id: "fy",
    label: "West Frisian",
    native: "Frysk",
  },
  {
    id: "ga",
    label: "Irish",
    native: "Gaeilge",
  },
  {
    id: "gd",
    label: "Scottish Gaelic",
    native: "Gàidhlig",
  },
  {
    id: "gl",
    label: "Galician",
    native: "Galego",
  },
  {
    id: "gn",
    label: "Guarani",
    native: "Avañe'ẽ",
  },
  {
    id: "gu",
    label: "Gujarati",
    native: "ગુજરાતી",
  },
  {
    id: "gv",
    label: "Manx",
    native: "Gaelg",
  },
  {
    id: "ha",
    label: "Hausa",
    native: "هَوُسَ",
    rtl: 1,
  },
  {
    id: "he",
    label: "Hebrew",
    native: "עברית",
    rtl: 1,
  },
  {
    id: "hi",
    label: "Hindi",
    native: "हिन्दी",
  },
  {
    id: "ho",
    label: "Hiri Motu",
    native: "Hiri Motu",
  },
  {
    id: "hr",
    label: "Croatian",
    native: "Hrvatski",
  },
  {
    id: "ht",
    label: "Haitian",
    native: "Krèyol ayisyen",
  },
  {
    id: "hu",
    label: "Hungarian",
    native: "Magyar",
  },
  {
    id: "hy",
    label: "Armenian",
    native: "Հայերեն",
  },
  {
    id: "hz",
    label: "Herero",
    native: "Otsiherero",
  },
  {
    id: "ia",
    label: "Interlingua",
    native: "Interlingua",
  },
  {
    id: "id",
    label: "Indonesian",
    native: "Bahasa Indonesia",
  },
  {
    id: "ie",
    label: "Interlingue",
    native: "Interlingue",
  },
  {
    id: "ig",
    label: "Igbo",
    native: "Igbo",
  },
  {
    id: "ii",
    label: "Sichuan Yi",
    native: "ꆇꉙ / 四川彝语",
  },
  {
    id: "ik",
    label: "Inupiak",
    native: "Iñupiak",
  },
  {
    id: "io",
    label: "Ido",
    native: "Ido",
  },
  {
    id: "is",
    label: "Icelandic",
    native: "Íslenska",
  },
  {
    id: "it",
    label: "Italian",
    native: "Italiano",
  },
  {
    id: "iu",
    label: "Inuktitut",
    native: "ᐃᓄᒃᑎᑐᑦ",
  },
  {
    id: "ja",
    label: "Japanese",
    native: "日本語",
  },
  {
    id: "jv",
    label: "Javanese",
    native: "Basa Jawa",
  },
  {
    id: "ka",
    label: "Georgian",
    native: "ქართული",
  },
  {
    id: "kg",
    label: "Kongo",
    native: "KiKongo",
  },
  {
    id: "ki",
    label: "Kikuyu",
    native: "Gĩkũyũ",
  },
  {
    id: "kj",
    label: "Kuanyama",
    native: "Kuanyama",
  },
  {
    id: "kk",
    label: "Kazakh",
    native: "Қазақша",
  },
  {
    id: "kl",
    label: "Greenlandic",
    native: "Kalaallisut",
  },
  {
    id: "km",
    label: "Cambodian",
    native: "ភាសាខ្មែរ",
  },
  {
    id: "kn",
    label: "Kannada",
    native: "ಕನ್ನಡ",
  },
  {
    id: "ko",
    label: "Korean",
    native: "한국어",
  },
  {
    id: "kr",
    label: "Kanuri",
    native: "Kanuri",
  },
  {
    id: "ks",
    label: "Kashmiri",
    native: "कश्मीरी / كشميري",
    rtl: 1,
  },
  {
    id: "ku",
    label: "Kurdish",
    native: "Kurdî / كوردی",
    rtl: 1,
  },
  {
    id: "kv",
    label: "Komi",
    native: "Коми",
  },
  {
    id: "kw",
    label: "Cornish",
    native: "Kernewek",
  },
  {
    id: "ky",
    label: "Kirghiz",
    native: "Kırgızca / Кыргызча",
  },
  {
    id: "la",
    label: "Latin",
    native: "Latina",
  },
  {
    id: "lb",
    label: "Luxembourgish",
    native: "Lëtzebuergesch",
  },
  {
    id: "lg",
    label: "Ganda",
    native: "Luganda",
  },
  {
    id: "li",
    label: "Limburgian",
    native: "Limburgs",
  },
  {
    id: "ln",
    label: "Lingala",
    native: "Lingála",
  },
  {
    id: "lo",
    label: "Laotian",
    native: "ລາວ / Pha xa lao",
  },
  {
    id: "lt",
    label: "Lithuanian",
    native: "Lietuvių",
  },
  {
    id: "lu",
    label: "Luba-Katanga",
    native: "Tshiluba",
  },
  {
    id: "lv",
    label: "Latvian",
    native: "Latviešu",
  },
  {
    id: "mg",
    label: "Malagasy",
    native: "Malagasy",
  },
  {
    id: "mh",
    label: "Marshallese",
    native: "Kajin Majel / Ebon",
  },
  {
    id: "mi",
    label: "Maori",
    native: "Māori",
  },
  {
    id: "mk",
    label: "Macedonian",
    native: "Македонски",
  },
  {
    id: "ml",
    label: "Malayalam",
    native: "മലയാളം",
  },
  {
    id: "mn",
    label: "Mongolian",
    native: "Монгол",
  },
  {
    id: "mo",
    label: "Moldovan",
    native: "Moldovenească",
  },
  {
    id: "mr",
    label: "Marathi",
    native: "मराठी",
  },
  {
    id: "ms",
    label: "Malay",
    native: "Bahasa Melayu",
  },
  {
    id: "mt",
    label: "Maltese",
    native: "bil-Malti",
  },
  {
    id: "my",
    label: "Burmese",
    native: "မြန်မာစာ",
  },
  {
    id: "na",
    label: "Nauruan",
    native: "Dorerin Naoero",
  },
  {
    id: "nb",
    label: "Norwegian Bokmål",
    native: "Norsk bokmål",
  },
  {
    id: "nd",
    label: "North Ndebele",
    native: "Sindebele",
  },
  {
    id: "ne",
    label: "Nepali",
    native: "नेपाली",
  },
  {
    id: "ng",
    label: "Ndonga",
    native: "Oshiwambo",
  },
  {
    id: "nl",
    label: "Dutch",
    native: "Nederlands",
  },
  {
    id: "nn",
    label: "Norwegian Nynorsk",
    native: "Norsk nynorsk",
  },
  {
    id: "no",
    label: "Norwegian",
    native: "Norsk",
  },
  {
    id: "nr",
    label: "South Ndebele",
    native: "isiNdebele",
  },
  {
    id: "nv",
    label: "Navajo",
    native: "Diné bizaad",
  },
  {
    id: "ny",
    label: "Chichewa",
    native: "Chi-Chewa",
  },
  {
    id: "oc",
    label: "Occitan",
    native: "Occitan",
  },
  {
    id: "oj",
    label: "Ojibwa",
    native: "ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin",
  },
  {
    id: "om",
    label: "Oromo",
    native: "Oromoo",
  },
  {
    id: "or",
    label: "Oriya",
    native: "ଓଡ଼ିଆ",
  },
  {
    id: "os",
    label: "Ossetian / Ossetic",
    native: "Иронау",
  },
  {
    id: "pa",
    label: "Panjabi / Punjabi",
    native: "ਪੰਜਾਬੀ / पंजाबी / پنجابي",
  },
  {
    id: "pi",
    label: "Pali",
    native: "Pāli / पाऴि",
  },
  {
    id: "pl",
    label: "Polish",
    native: "Polski",
  },
  {
    id: "ps",
    label: "Pashto",
    native: "پښتو",
    rtl: 1,
  },
  {
    id: "pt",
    label: "Portuguese",
    native: "Português",
  },
  {
    id: "qu",
    label: "Quechua",
    native: "Runa Simi",
  },
  {
    id: "rm",
    label: "Raeto Romance",
    native: "Rumantsch",
  },
  {
    id: "rn",
    label: "Kirundi",
    native: "Kirundi",
  },
  {
    id: "ro",
    label: "Romanian",
    native: "Română",
  },
  {
    id: "ru",
    label: "Russian",
    native: "Русский",
  },
  {
    id: "rw",
    label: "Rwandi",
    native: "Kinyarwandi",
  },
  {
    id: "sa",
    label: "Sanskrit",
    native: "संस्कृतम्",
  },
  {
    id: "sc",
    label: "Sardinian",
    native: "Sardu",
  },
  {
    id: "sd",
    label: "Sindhi",
    native: "सिनधि",
  },
  {
    id: "se",
    label: "Northern Sami",
    native: "Sámegiella",
  },
  {
    id: "sg",
    label: "Sango",
    native: "Sängö",
  },
  {
    id: "sh",
    label: "Serbo-Croatian",
    native: "Srpskohrvatski / Српскохрватски",
  },
  {
    id: "si",
    label: "Sinhalese",
    native: "සිංහල",
  },
  {
    id: "sk",
    label: "Slovak",
    native: "Slovenčina",
  },
  {
    id: "sl",
    label: "Slovenian",
    native: "Slovenščina",
  },
  {
    id: "sm",
    label: "Samoan",
    native: "Gagana Samoa",
  },
  {
    id: "sn",
    label: "Shona",
    native: "chiShona",
  },
  {
    id: "so",
    label: "Somalia",
    native: "Soomaaliga",
  },
  {
    id: "sq",
    label: "Albanian",
    native: "Shqip",
  },
  {
    id: "sr",
    label: "Serbian",
    native: "Српски",
  },
  {
    id: "ss",
    label: "Swati",
    native: "SiSwati",
  },
  {
    id: "st",
    label: "Southern Sotho",
    native: "Sesotho",
  },
  {
    id: "su",
    label: "Sundanese",
    native: "Basa Sunda",
  },
  {
    id: "sv",
    label: "Swedish",
    native: "Svenska",
  },
  {
    id: "sw",
    label: "Swahili",
    native: "Kiswahili",
  },
  {
    id: "ta",
    label: "Tamil",
    native: "தமிழ்",
  },
  {
    id: "te",
    label: "Telugu",
    native: "తెలుగు",
  },
  {
    id: "tg",
    label: "Tajik",
    native: "Тоҷикӣ",
  },
  {
    id: "th",
    label: "Thai",
    native: "ไทย / Phasa Thai",
  },
  {
    id: "ti",
    label: "Tigrinya",
    native: "ትግርኛ",
  },
  {
    id: "tk",
    label: "Turkmen",
    native: "Туркмен / تركمن",
  },
  {
    id: "tl",
    label: "Tagalog / Filipino",
    native: "Tagalog",
  },
  {
    id: "tn",
    label: "Tswana",
    native: "Setswana",
  },
  {
    id: "to",
    label: "Tonga",
    native: "Lea Faka-Tonga",
  },
  {
    id: "tr",
    label: "Turkish",
    native: "Türkçe",
  },
  {
    id: "ts",
    label: "Tsonga",
    native: "Xitsonga",
  },
  {
    id: "tt",
    label: "Tatar",
    native: "Tatarça",
  },
  {
    id: "tw",
    label: "Twi",
    native: "Twi",
  },
  {
    id: "ty",
    label: "Tahitian",
    native: "Reo Mā`ohi",
  },
  {
    id: "ug",
    label: "Uyghur",
    native: "Uyƣurqə / ئۇيغۇرچە",
  },
  {
    id: "uk",
    label: "Ukrainian",
    native: "Українська",
  },
  {
    id: "ur",
    label: "Urdu",
    native: "اردو",
    rtl: 1,
  },
  {
    id: "uz",
    label: "Uzbek",
    native: "Ўзбек",
  },
  {
    id: "ve",
    label: "Venda",
    native: "Tshivenḓa",
  },
  {
    id: "vi",
    label: "Vietlabelse",
    native: "Tiếng Việt",
  },
  {
    id: "vo",
    label: "Volapük",
    native: "Volapük",
  },
  {
    id: "wa",
    label: "Walloon",
    native: "Walon",
  },
  {
    id: "wo",
    label: "Wolof",
    native: "Wollof",
  },
  {
    id: "xh",
    label: "Xhosa",
    native: "isiXhosa",
  },
  {
    id: "yi",
    label: "Yiddish",
    native: "ייִדיש",
    rtl: 1,
  },
  {
    id: "yo",
    label: "Yoruba",
    native: "Yorùbá",
  },
  {
    id: "za",
    label: "Zhuang",
    native: "Cuengh / Tôô / 壮语",
  },
  {
    id: "zh",
    label: "Chinese",
    native: "中文",
  },
  {
    id: "zu",
    label: "Zulu",
    native: "isiZulu",
  },
];
