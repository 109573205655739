import { Combobox } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { t } from "i18next";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { Content } from "../../../types/Contacts";

interface Props {
  name: string;
  placeholder: string;
  data: Content[] | null;
  icon?: boolean;
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
  handleAddMember: (id?: string | undefined, type?: string | undefined) => void;
  disabled?: boolean;
}

type SelectedT = {
  id: string;
  type: string;
  email: string;
};

const DistributorComboBox = ({
  name,
  placeholder,
  data,
  icon,
  query,
  setQuery,
  handleAddMember,
  disabled = false,
}: Props) => {
  const [selected, setSelected] = useState<SelectedT | null>(null);

  const handleBold = useCallback(
    (originalString: string) => {
      const index = originalString?.indexOf(query);
      const boldString =
        "<b>" +
        originalString?.substring(index, index + query?.length) +
        "</b>";
      const newString =
        originalString?.substring(0, index) +
        boldString +
        originalString?.substring(index + query?.length);

      return newString;
    },
    [query]
  );

  return (
    <>
      <div className="flex flex-col justify-start w-full relative">
        <Combobox value={query} disabled={disabled}>
          <div className="relative flex flex-row items-center text-black">
            {icon && <MagnifyingGlassIcon className="absolute ml-2 w-6 h-6" />}
            <Combobox.Input
              onChange={(event) => setQuery(event.target.value)}
              name={name}
              id={name}
              placeholder={placeholder}
              className={`w-full py-1 px-4 border-[1px] border-theme-distributor rounded-lg  placeholder:text-center`}
              value={query}
              autoComplete="off"
            />
          </div>
          <Combobox.Options className="border-[1px] border-theme-distributor rounded-md mt-1 absolute z-20 top-8 bg-white cursor-pointer">
            {data?.map((content) => (
              <div key={content?.id}>
                {query.length > 0 && (
                  <Combobox.Option
                    value={content?.id}
                    className={`rounded-lg border-theme-distributor px-4 py-2 hover:bg-theme-distributor`}
                    onClick={() => {
                      setSelected({
                        id: content.id,
                        type: content.type,
                        email: content.mainEmail,
                      });
                      setQuery(content.mainEmail);
                    }}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: handleBold(content?.displayName + `(${content?.mainEmail})`),
                      }}
                    />
                  </Combobox.Option>
                )}
              </div>
            ))}
          </Combobox.Options>
        </Combobox>
      </div>
      <button
        className="w-full md:w-auto border-[1px] border-black bg-white rounded-full text-base text-black font-bold px-2 py-1 h-full self-end min-w-[196px] disabled:bg-gray-100 disabled:border-gray-300 disabled:text-gray-300"
        disabled={selected?.email !== query}
        onClick={(e) => {
          e.preventDefault();
          handleAddMember(selected?.id, selected?.type);
          setSelected(null);
          setQuery("");
        }}
      >
        {t("form_distributor_manage_button")}
      </button>
    </>
  );
};

export default DistributorComboBox;
