import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useDeviceType from "../../Context/useDeviceType";

const FooterMobile = () => {
  const { t } = useTranslation();

  return (
    <div className="w-[100vw] border-r-gray-200 border-t-[1px] mb-10">
      <div className="flex flex-col px-4 gap-4 pt-4">
        <div className="flex flex-col">
          <span className="text-theme-distributor font-bold text-sm">
            {t("footer_left")}
          </span>
          <span className="text-theme-distributor font-bold text-sm">
            {t("footer_right")}
          </span>
        </div>
        <Link
          to="#"
          className="text-theme-distributor font-bold text-sm underline"
        >
          {t("footer_right_link_1")}
        </Link>
      </div>
    </div>
  );
};

const FooterDesktop = () => {
  const { t } = useTranslation();

  return (
    <div className="xl:max-w-[1200px] 2xl:max-w-[1640px] w-[100vw] border-r-gray-200 border-t-[1px] mb-10">
      <div className="flex justify-between px-4">
        <span className="text-theme-distributor font-bold text-sm pt-4">
          {t("footer_left")}
        </span>
        <div className="flex flex-row gap-4">
          <span className="text-theme-distributor font-bold text-sm pt-4">
            {t("footer_right")} -{" "}
            <Link
              to="https://napps.solutions/"
              target="_blank"
              className="text-theme-distributor font-bold text-sm pt-4 underline"
            >
              {t("footer_right_link")}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

const Footer = () => {
  const device = useDeviceType();

  return <> {device === "Phone" ? <FooterMobile /> : <FooterDesktop />}</>;
};
export default Footer;
