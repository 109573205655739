import axios from "axios";
interface Department {
    name: string;
};
async function GetDepartments() : Promise<Department[]> {
    const headersList = {
        Accept: "*/*"
    };

    const reqOptions = {
        url: `${process.env.REACT_APP_DOMAIN}/api/v1/departments/all`,
        method: "GET",
        headers: headersList
    };

    return (await axios.request(reqOptions)).data;
}

export default GetDepartments;
