import {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Cross2Icon, EnvelopeOpenIcon, Link2Icon } from "@radix-ui/react-icons";
import FormInput from "../../common/FormComponents/FormInput";
import {
  Item,
  SelectForm,
} from "../../common/FormComponents/SelectForm/SelectForm";
import FormTextArea from "../../common/FormComponents/FormTextArea";
import TableContacts from "./TableContacts";
import { getList } from "country-list-with-dial-code-and-flag";
import {
  CompanyFormData,
  CompanyMarksT,
  ContactMethodsT,
  ComboBoxT,
  DepartmentResponseT,
} from "../../../types/global";
import ContactMethods from "../../common/FormComponents/ContactMethods/ContactMethods";
import ContactFormInput from "../../common/FormComponents/ContactMethods/ContactFormInput";
import FormInputTags from "../../common/FormComponents/FormInputTags";
import FormHeaderCreate from "../../common/FormComponents/FormHeader/FormHeaderCreate";
import FormFooterCreate from "../../common/FormComponents/FormFooter/FormFooterCreate";
import { Content, Membership } from "../../../types/Companies";
import UpdateFunction from "../../../lib/UpdateFunction";
import FormHeaderNormal from "../../common/FormComponents/FormHeader/FormHeaderNormal";
import CreateFunction from "../../../lib/CreateFunction";
import useProgressHook from "../../../Context/UseProgressContext";
import Spinner from "../../common/Spinner/Spinner";
import useTableHook from "../../../Context/UseTableContext";
import Memberships from "../../common/FormComponents/Memberships/Memberships";
import useFormNavigation from "../../../Context/UseFormNavigationContext";
import FormFooterNormal from "../../common/FormComponents/FormFooter/FormFooterNormal";
import useUserHook from "../../../Context/UseUserContext";
import useDeviceType from "../../../Context/useDeviceType";
import TableContactsMobile from "./TableContactsMobile";
import PhoneContact from "../../common/FormComponents/ContactMethods/PhoneContact";
import ErrorBoundary from "../../common/ErrorBoundary";
import axios from "axios";
import FormComboBox from "../../common/FormComponents/ComboBox/FormComboBox";
import FormMultiComboBox from "../../common/FormComponents/FormMultiComboBox/FormMultiComboBox";
import { LANGUAGES_CODE } from "../../../mocks/LANGUAGES_CODE";

interface Props {
  setIsOpen: (isOpen: boolean) => void;
  setSelectedCompany: ((selectedCompany: Item) => void | null) | undefined;
  edit: Content | any;
  independent?: boolean;
}

const CompanyForm = ({
  setIsOpen,
  setSelectedCompany,
  edit: editProps,
  independent = false
}: Props) => {
  const [edit, setEdit] = useState(() => editProps);
  const { t } = useTranslation();
  const progressProvider = useProgressHook();
  const formNavigation = useFormNavigation();
  const useUser = useUserHook();
  const device = useDeviceType();

  const useTable = useTableHook();
  const formRef = useRef<HTMLFormElement | any>();
  const [tags, setTags] = useState<string[]>([]);
  const [contactMethods, setContactMethods] = useState<ContactMethodsT[]>([]);

  const [name, setName] = useState("");
  const [remarks, setRemarks] = useState("");
  const [country, setCountry] = useState<Item | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedContactLists, setSelectedContactLists] = useState<Item | null>(
    null
  );
  const [selectedMembership, setSelectedMembership] = useState<
    Membership | undefined
  >(undefined);

  const [consentDate, setConsentDate] = useState("");
  const [selectedGdpr, setSelectedGdpr] = useState<ComboBoxT | null>(null);

  const [selectedDepartment, setSelectedDepartment] =
    useState<ComboBoxT | null>(null);

  const [selectedLanguage, setSelectedLanguage] = useState<ComboBoxT[]>([]);

  const [attachments, setAttachments] = useState<File[] | null>(null);

  const [customFields, setCustomfields] = useState<string[] | null>(null);
  const [departments, setDepartments] = useState<ComboBoxT[] | null>(null);

  const countryList = useMemo(() => {
    return getList().map((country, index) => {
      return { id: index.toString(), name: country.code };
    });
  }, []);

  const handleCreateCompany = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      event.stopPropagation();

      setIsLoading(true);

      const form = new FormData(event.target as HTMLFormElement);

      const dataForm = Object.fromEntries(form);

      //pick up the custom fields from from data.
      const customFieldsBody = customFields?.reduce((acc, key) => {
        if (dataForm.hasOwnProperty(key)) {
          acc[key] = dataForm[key];
        }
        return acc;
      }, {} as Record<string, any>);

      const bodyContent = JSON.stringify({
        mainEmail: dataForm.email,
        remarks: dataForm.remarks,
        address: {
          streetName: dataForm.street,
          place: dataForm.place,
          zipCode: dataForm.zipcode,
          country: country?.name ?? "",
        },
        name: dataForm.name,
        branch: dataForm.branch,
        category: dataForm.category,
        homepage: dataForm.homepage,
        customFields: customFieldsBody ?? {},
        department: selectedDepartment?.label ?? null,
        languages:
          selectedLanguage.map((language: ComboBoxT) => language.id) ?? [],
      });

      if (edit) {
        const pathCM = contactMethods.filter((previousValue) => {
          const findEl = edit.contactMethods.find(
            (el: ContactMethodsT) => el.id === previousValue.id
          );

          return (
            previousValue?.value !== findEl?.value &&
            previousValue?.id === findEl?.id
          );
        });

        const postCM = contactMethods.filter((previousValue) => {
          const findEl = edit.contactMethods.find(
            (el: ContactMethodsT) => el.id === previousValue.id
          );

          return previousValue?.id !== findEl?.id;
        });

        const deleteCM = edit.contactMethods.filter(
          (previousValue: ContactMethodsT) => {
            const findEl = contactMethods.find(
              (el: ContactMethodsT) => el.id === previousValue.id
            );

            return previousValue?.id !== findEl?.id;
          }
        );

        const postTag = tags.filter((tag: string) => {
          return edit.tags.indexOf(tag) === -1;
        });

        const deleteTag = edit.tags.filter((tag: string) => {
          return tags.indexOf(tag) === -1;
        });

        const updates = { pathCM, postCM, deleteCM, postTag, deleteTag };

        const membershipFunctions = {
          selectedMembership,
          consentDate,
          selectedGdpr,
          attachments,
          selectedContactLists,
        };
        //Update Patch
        UpdateFunction(
          "companies",
          edit?.id,
          bodyContent,
          setSelectedCompany,
          updates,
          membershipFunctions
        );
      } else {
        const membershipFunctions = {
          selectedContactLists,
          consentDate,
          selectedGdpr,
        };

        //Create
        CreateFunction(
          "companies",
          bodyContent,
          setSelectedCompany,
          contactMethods,
          tags,
          membershipFunctions
        );
      }
      setIsOpen(false);
      setIsLoading(false);
      setTimeout(() => useTable?.refreshTable(), 1000);
    },
    [
      country?.name,
      edit,
      setIsOpen,
      contactMethods,
      tags,
      selectedMembership,
      consentDate,
      selectedGdpr,
      attachments,
      setSelectedCompany,
      selectedContactLists,
      useTable,
      customFields,
      selectedDepartment,
      selectedLanguage,
    ]
  );

  const handleDeleteContact = useCallback(
    (selectedContact: ContactMethodsT) => {
      setContactMethods(
        contactMethods.filter((contact) => contact.id !== selectedContact.id)
      );
    },
    [contactMethods]
  );

  const handleAdditionContact = useCallback(
    (contact: ContactMethodsT) => {
      setContactMethods([...contactMethods, contact]);
    },
    [contactMethods]
  );

  const handleUpdateContact = useCallback(
    (selectedContact: ContactMethodsT, newValue: string) => {
      const newState = structuredClone(contactMethods);

      newState.find((el) => el.id === selectedContact.id)!.value = newValue;

      setContactMethods(newState);
    },
    [contactMethods]
  );

  const handleDeleteTag = useCallback(
    (selectedTag: string) => {
      setTags(tags.filter((tag) => tag !== selectedTag));
    },
    [tags]
  );

  const handleAdditionTag = useCallback(
    (tag: string) => {
      if (!tags.find((el) => el === tag)) {
        setTags([...tags, tag]);
      }
    },
    [tags]
  );

  const handleUpdateProgressContext = useCallback(
    (newProgressData: CompanyMarksT) => {
      progressProvider?.setHandleCompanyMarks(newProgressData);
    },
    []
  );

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_DOMAIN}/api/v1/customFields`, {
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setCustomfields(response?.data.map((el: string) => el.trim()));
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_DOMAIN}/api/v1/departments/all`, {
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setDepartments(
          response.data.map((el: DepartmentResponseT) => {
            return { id: el.name, label: el.name };
          })
        );
      })
      .catch((error) => console.log(error));
  }, []);

  const handleResetForm = useCallback(() => {
    if (formRef) {
      formRef?.current?.reset();
      if (edit) {
        const dataFromTable: CompanyFormData | any = {
          branch: true,
          category: true,
          email: true,
          homepage: true,
          name: true,
          place: true,
          remarks: true,
          street: true,
          zipcode: true,
        };

        Object.keys(dataFromTable).forEach((attribute) => {
          formRef?.current?.[attribute]?.setAttribute("value", "");
        });

        const newProgressData = {
          ...(dataFromTable as CompanyMarksT),
          contactMethods: true,
          tags: true,
          country: true,
        };

        handleUpdateProgressContext(newProgressData);
      }
      setName("");
      setCountry(null);
      setContactMethods([]);
      setTags([]);
      setRemarks("");
    }
  }, [edit, handleUpdateProgressContext]);

  useEffect(() => {
    setIsLoading(true);
    handleResetForm();

    //Normal inputs
    const dataFromTable: CompanyFormData | any = {
      branch: edit?.branch ?? "",
      category: edit?.category ?? "",
      email: edit?.mainEmail ?? "",
      homepage: edit?.homepage ?? "",
      name: edit?.name ?? "",
      place: edit?.address?.place ?? "",
      remarks: edit?.remarks ?? "",
      street: edit?.address?.streetName ?? "",
      zipcode: edit?.address?.zipCode ?? "",
    };

    if (edit) {
      Object.keys(dataFromTable).forEach((attribute) => {
        formRef?.current?.[attribute]?.setAttribute(
          "value",
          dataFromTable[attribute]
        );
      });

      //Name state
      setName(edit?.name);

      //Country
      if (edit?.address?.country) {
        const countryId = countryList.find(
          (el) => el.name === edit?.address?.country
        )?.id;

        setCountry({
          id: countryId ?? "",
          name: edit?.address?.country,
        });
      }

      //Contact Methods
      if (edit?.contactMethods) {
        setContactMethods(edit?.contactMethods);
      }

      //Tags
      if (edit?.tags) {
        setTags(edit?.tags);
      }

      //remarks
      if (edit?.remarks) {
        setRemarks(edit.remarks);
      }

      //Department
      if (edit?.department && departments) {
        const department = edit?.department;
        setSelectedDepartment({ id: department, label: department });
      }

      //languages
      if (edit?.languages as ComboBoxT[]) {
        const languages = edit?.languages.map((el: string) => {
          const find = LANGUAGES_CODE.find((language) => language.id === el);
          return { id: find?.id, label: find?.label };
        });

        setSelectedLanguage(languages);
      }

      // set ProgressBar
      const progressData = Object.entries(
        dataFromTable as CompanyFormData
      ).reduce((acc, [key, value]) => {
        acc[key] = value.length === 0;
        return acc;
      }, {} as { [key: string]: boolean });

      const newProgressData = {
        ...(progressData as CompanyMarksT),
        contactMethods: edit?.contactMethods?.length > 0 ? false : true,
        tags: edit?.tags?.length > 0 ? false : true,
        country: edit?.address?.country?.length > 0 ? false : true,
      };

      handleUpdateProgressContext(newProgressData);
    }
    setIsLoading(false);
  }, [
    countryList,
    edit,
    handleResetForm,
    handleUpdateProgressContext,
    departments,
  ]);

  useEffect(() => {
    if (!setSelectedCompany) {
      if (independent === false || independent === undefined) {
        formNavigation?.setCurrentItem(editProps);
      }
    }
  }, [editProps, setSelectedCompany, independent]);

  useEffect(() => {
    if (!setSelectedCompany) {
      if (independent === false || independent === undefined) {
        setEdit(formNavigation?.currentItem);
      }
    }
  }, [editProps, formNavigation?.currentItem, setSelectedCompany, independent]);

  if (isLoading) {
    return (
      <div className="fixed inset-0 z-20 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-20 top-0 left-0">
        <Spinner customClass="fill-theme-company" size="h-20 w-20" />
      </div>
    );
  }

  const canEdit = !edit || (useUser?.user?.role !== "READER" &&
      (useUser?.user?.role !== "EDITOR" || useUser?.user?.department === edit?.department));

  return (
    <>
      <form
        onSubmit={handleCreateCompany}
        ref={formRef}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        {edit ? (
          <ErrorBoundary>
            <FormHeaderNormal
              titleName={name}
              type="company"
              subtitle_1=""
              subtitle_2=""
              titleTheme="text-theme-company"
              isDoubleDialog={setSelectedCompany ? true : false}
              id={edit?.id}
              contacts={{
                email: edit?.mainEmail,
                phone:
                  contactMethods?.find((el) => el.type === "PHONE")?.value ??
                  undefined,
              }}
            />
          </ErrorBoundary>
        ) : (
          <ErrorBoundary>
            <FormHeaderCreate
              titleName={name}
              type="company"
              titleTheme="text-theme-company"
            />
          </ErrorBoundary>
        )}

        {/* Company Detail */}
        <div className="flex flex-col py-6 border-y-[1px] border-divisor">
          <h3 className="text-black font-bold text-xl pb-4">
            {t("form_company_details_title")}
          </h3>
          <div className="flex flex-row justify-between gap-x-16 gap-y-4 pt-4 md:flex-nowrap flex-wrap">
              {useUser?.user?.role !== "READER" && useUser?.user?.role !== "EDITOR" && <FormComboBox
                  label={t("form_department") ?? ""}
                  selectedField={selectedDepartment}
                  setSelectedfield={setSelectedDepartment}
                  fields={departments ?? []}
              fullWidth
            />}
            <FormInput
              required
              id="name"
              name="name"
              label={t("form_company_details_label_medium")}
              fullWidth
              doNotMark={!edit}
              disabled={!canEdit}
            />
            <FormInput
              id="branch"
              name="branch"
              label={t("form_company_details_label_branch")}
              fullWidth
              doNotMark={!edit}
              disabled={!canEdit}
            />
            <FormInput
              id="category"
              name="category"
              label={t("form_company_details_label_category")}
              fullWidth
              doNotMark={!edit}
              disabled={!canEdit}
            />
          </div>
          <div className="flex flex-row justify-start gap-x-16 gap-y-4 pt-4 flex-wrap">
            <FormInput
              id="email"
              name="email"
              label={t("form_company_details_label_email")}
              customClass={
                edit
                  ? `max-w-[280px] min-w-[280px]`
                  : `max-w-[310px] min-w-[310px]`
              }
              required
              type="email"
              doNotMark={!edit}
              disabled={!canEdit}
              linkButton={
                edit?.mainEmail && (
                  <a
                    href={`mailto:${edit.mainEmail}`}
                    target="_blank"
                    rel="noreferrer"
                    className="cursor-pointer flex gap-2 items-center"
                  >
                    <EnvelopeOpenIcon
                      className="text-gray-400"
                      width={20}
                      height={20}
                    />
                  </a>
                )
              }
            />

            {!contactMethods.find((el) => el.type === "PHONE") && (
              <ErrorBoundary>
                <PhoneContact
                  handleAdditionContact={handleAdditionContact}
                  handleUpdateContact={handleUpdateContact}
                  disabled={!canEdit}
                />
              </ErrorBoundary>
            )}

            {contactMethods.map((contact) => (
              <ErrorBoundary key={contact.id}>
                <ContactFormInput
                  handleDeleteContact={handleDeleteContact}
                  contact={contact}
                  handleUpdateContact={handleUpdateContact}
                  disabled={!canEdit}
                />
              </ErrorBoundary>
            ))}

            <ErrorBoundary>
              <ContactMethods
                handleAdditionContact={handleAdditionContact}
                marked={contactMethods.length === 0 && edit}
                disabled={!canEdit}
              />
            </ErrorBoundary>
          </div>

          <div className="flex md:flex-nowrap flex-wrap justify-between gap-16 pt-4">
            <FormTextArea
              id="remarks"
              name="remarks"
              label={t("form_company_details_label_remarks")}
              defaultValue={remarks}
              doNotMark={!edit}
              disabled={!canEdit}
            />
            <div className="flex flex-col w-full break-all">
              <ErrorBoundary>
                <FormInputTags
                  id="tags"
                  name="tags"
                  label={t("form_company_details_label_keywords")}
                  handleAdditionTag={handleAdditionTag}
                  placeholder={
                    t("form_company_details_keywords_placeholder") ?? ""
                  }
                  marked={tags.length === 0 && edit}
                  disabled={!canEdit}
                />
              </ErrorBoundary>
              <div className="flex flex-row gap-2 flex-wrap py-4">
                {tags
                  .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))
                  .map((tag) => (
                    <div
                      className="flex flex-row items-center gap-4 bg-black rounded-2xl text-white w-fit px-3 py-1"
                      key={tag}
                    >
                      #{tag}
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          if (useUser?.user?.role !== "READER") {
                            handleDeleteTag(tag);
                          }
                        }}
                      >
                        <Cross2Icon />
                      </button>
                    </div>
                  ))}
              </div>
              <FormMultiComboBox
                label={t("form_language") ?? ""}
                selectedField={selectedLanguage}
                setSelectedfield={setSelectedLanguage as any}
                fields={LANGUAGES_CODE}
              />
            </div>
          </div>
          <div className="flex md:flex-nowrap flex-wrap justify-between gap-16 pt-4">
            <div className="flex items-end gap-2">
              <FormInput
                name="homepage"
                id="homepage"
                label={t("form_company_details_label_twitter")}
                customClass={"max-w-[326px]"}
                fullWidth
                doNotMark={!edit}
                disabled={!canEdit}
                linkButton={
                  edit?.homepage && (
                    <a
                      href={edit.homepage}
                      target="_blank"
                      rel="noreferrer"
                      className="cursor-pointer flex gap-2 items-center"
                    >
                      <Link2Icon
                        className="text-gray-400"
                        width={20}
                        height={20}
                      />
                    </a>
                  )
                }
                required
                type="url"
              />
            </div>
          </div>
        </div>

        {/* Custom Fields Data*/}
        {customFields && (
          <div className="flex flex-col py-6 border-y-[1px] border-divisor">
            <h3 className="text-black font-bold text-xl pb-4">
              {t("custom_fields_section_title")}
            </h3>
            <ErrorBoundary>
              <div className="flex flex-row justify-start gap-8 flex-wrap">
                {customFields.map((el) => (
                  <FormInput
                    key={el}
                    id={el}
                    name={el}
                    label={el}
                    doNotMark={true}
                    disabled={!canEdit}
                    customClass={"min-w-[326px]"}
                    defaultValue={edit?.customFields[el] ?? ""}
                  />
                ))}
              </div>
            </ErrorBoundary>
          </div>
        )}

        {/* Address Data*/}
        <div className="flex flex-col py-6 border-y-[1px] border-divisor">
          <h3 className="text-black font-bold text-xl pb-4">
            {t("form_company_address_title")}
          </h3>
          <div className="flex md:flex-nowrap flex-wrap justify-between gap-8">
            <FormInput
              id="street"
              name="street"
              label={t("form_company_address_street")}
              fullWidth
              doNotMark={!edit}
              disabled={!canEdit}
              customClass={"w-[480px]"}
            />
            <FormInput
              id="place"
              name="place"
              label={t("form_company_address_place")}
              fullWidth
              doNotMark={!edit}
              disabled={!canEdit}
              customClass={"w-[240px]"}
            />
            <FormInput
              id="zipcode"
              name="zipcode"
              label={t("form_company_address_zipcode")}
              fullWidth
              doNotMark={!edit}
              disabled={!canEdit}
            />
            <SelectForm
              name="country"
              label={t("form_company_address_country")}
              items={countryList}
              value={country}
              setValue={setCountry}
              doNotMark={!edit}
              disabled={!canEdit}
            />
          </div>
        </div>

        {/* table contacts */}
        {edit && (
          <div className="flex flex-col py-6 border-y-[1px] border-divisor">
            <h3 className="text-black font-bold text-xl pb-4">
              {t("form_company_business_table_title")}
            </h3>

            <div className="flex md:flex-nowrap flex-wrap ">
              <ErrorBoundary>
                {device === "Phone" ? (
                  <TableContactsMobile
                    data={edit?.people}
                    defaultData={[]}
                    isLoading={isLoading}
                  />
                ) : (
                  <TableContacts
                    data={edit?.people}
                    defaultData={[]}
                    isLoading={isLoading}
                  />
                )}
              </ErrorBoundary>
            </div>
          </div>
        )}
        <div className="flex flex-col py-6 border-y-[1px] border-divisor">
          <h3 className="text-black font-bold text-xl pb-4">
            {t("membership_title")}
          </h3>
          <ErrorBoundary>
            <Memberships
              memberships={edit?.memberships}
              setSelectedContactLists={setSelectedContactLists}
              selectedContactLists={selectedContactLists}
              setSelectedMembership={setSelectedMembership}
              attachments={attachments}
              consentDate={consentDate}
              selectedMembership={selectedMembership}
              setAttachments={setAttachments}
              setConsentDate={setConsentDate}
              setSelectedGdpr={setSelectedGdpr}
              selectedGdpr={selectedGdpr}
            />
          </ErrorBoundary>
        </div>
        {edit ? (
          <ErrorBoundary>
            <FormFooterNormal
              formType="companies"
              handleResetForm={handleResetForm}
              id={edit?.id}
              name={edit?.name}
              setIsOpenForm={setIsOpen}
              canEdit={canEdit}
            />
          </ErrorBoundary>
        ) : (
          <ErrorBoundary>
            <FormFooterCreate
              handleResetForm={handleResetForm}
              setIsOpen={setIsOpen}
            />
          </ErrorBoundary>
        )}
      </form>
    </>
  );
};

export default CompanyForm;
