import * as ProgressPrimitive from "@radix-ui/react-progress";
import { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import useProgressHook from "../../../Context/UseProgressContext";

interface ProgressProps {
  theme: { bgColor: string; borderColor: string; textColor: string };
  type: "person" | "company" | "distributor";
}

const FormProgressBar = ({ theme, type }: ProgressProps) => {
  const progressContext = useProgressHook();

  const { t } = useTranslation();

  const MessageBelow50 = useCallback(() => {
    return (
      <>
        <span className="font-bold text-base">{t("form_header_below")}</span>
        <div className="flex flex-row items-start w-full">
          <span className="flex flex-row gap-1">
            {t("form_header_check_yellow_1_below")}
            <strong className="flex flex-row">
              {t("form_header_check_yellow_2_below")}
              <div className="w-2 h-2 border-2 border-yellow-400 rounded-full bg-yellow-400"></div>
            </strong>
            {t("form_header_check_yellow_3_below")}
          </span>
        </div>
      </>
    );
  }, [t]);

  const MessageAbove50 = useCallback(() => {
    return (
      <>
        <span className="font-bold text-base">{t("form_header_above")}</span>
        <div className="flex flex-row items-start w-full">
          <span className="flex flex-row gap-1">
            {t("form_header_check_yellow_1_above")}
            <strong className="flex flex-row">
              {t("form_header_check_yellow_2_above")}
              <div className="w-2 h-2 border-2 border-yellow-400 rounded-full bg-yellow-400"></div>
            </strong>
            {t("form_header_check_yellow_3_above")}
          </span>
        </div>
      </>
    );
  }, []);

  const MessageComplete = useCallback(() => {
    return (
      <>
        <span className="font-bold text-base">{t("form_header_complete")}</span>
        <div className="flex flex-row items-start w-full">
          <span className="flex flex-row gap-1">
            {t("form_header_check_complete")}
          </span>
        </div>
      </>
    );
  }, []);

  useEffect(() => {
    progressContext?.setType(type);
  }, [progressContext, type]);

  return (
    <div className="flex md:flex-nowrap flex-wrap w-full md:pl-4">
      <div className="flex flex-col w-full">
        <h3 className="text-2xl font-bold py-4">
          {t("form_header_completeness")}
        </h3>
        {!progressContext?.average || progressContext?.average <= 50 ? (
          <MessageBelow50 />
        ) : (
          <></>
        )}

        {progressContext?.average &&
        progressContext?.average > 50 &&
        progressContext?.average < 100 ? (
          <MessageAbove50 />
        ) : (
          <></>
        )}

        {progressContext?.average && progressContext?.average === 100 ? (
          <MessageComplete />
        ) : (
          <></>
        )}
      </div>
      <div className="flex flex-col w-full justify-center">
        <ProgressPrimitive.Root
          value={progressContext?.average}
          className="h-5 w-full overflow-hidden rounded-full bg-white border-[1px] border-theme-distributor border-separate max-w-[228px]"
        >
          <ProgressPrimitive.Indicator
            style={{ width: `${progressContext?.average}%` }}
            className={`h-full ${theme.bgColor} duration-300 ease-in-out`}
          />
        </ProgressPrimitive.Root>
      </div>
    </div>
  );
};

export { FormProgressBar };
