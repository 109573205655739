import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { ComboBoxT } from "../../../../types/global";
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import { t } from "i18next";

interface Props {
  fields: ComboBoxT[];
  selectedField: ComboBoxT | null;
  setSelectedfield: Dispatch<SetStateAction<ComboBoxT | null>>;
  label?: string;
  fullWidth?: boolean;
  customClass?: string;
}

const FormComboBox = ({
  setSelectedfield,
  selectedField,
  fields,
  label,
  fullWidth = false,
  customClass,
}: Props) => {
  const [query, setQuery] = useState("");

  const filteredField =
    query === ""
      ? fields
      : fields.filter((field) => {
          return field.label.toLowerCase().includes(query.toLowerCase());
        });

  function compareDepartments(a: ComboBoxT | null, b: ComboBoxT | null) {
    return a?.id.toLowerCase() === b?.id.toLowerCase();
  }

  return (
    <div
      className={`flex flex-col justify-start ${
        fullWidth ? "w-full" : "w-auto"
      } `}
    >
      {label && (
        <label className="text-base font-bold pb-2 flex flex-row items-start gap-1">
          {label}
        </label>
      )}
      <Combobox
        value={selectedField}
        by={compareDepartments}
        onChange={setSelectedfield}
      >
        <div className="relative mt-1">
          <div className="relative w-full cursor-default rounded-lg text-left">
            <Combobox.Input
              className={`w-full py-1 px-1 pl-4 border-[1px] border-theme-distributor rounded-lg hover:bg-gray-50  hover:border-gray-500 ${
                customClass ?? ""
              }`}
              displayValue={(fields: any) => fields?.label}
              onChange={(event) => setQuery(event.target.value)}
              placeholder={t("form_person_data_select_placeholder")}
              defaultValue={selectedField?.label}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center p-4">
              <ChevronDownIcon aria-hidden="true" />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5">
              {filteredField.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                <>
                  <Combobox.Option
                    key={"empty"}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-8 pr-4 ${
                        active ? "bg-slate-200" : "text-black"
                      }`
                    }
                    value={null}
                  >
                    <span className={`block truncate font-normal h-6`}></span>
                  </Combobox.Option>
                  {filteredField.map((field) => (
                    <Combobox.Option
                      key={field.id}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-8 pr-4 ${
                          active ? "bg-slate-200" : "text-black"
                        }`
                      }
                      value={field}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate text-sm ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {field.label}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? "text-slate-400" : "text-black"
                              }`}
                            >
                              <CheckIcon aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </>
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

export default FormComboBox;
