import axios from "axios";

async function ChangeDepartment(id: string, newDepartment: string) {
    const headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
        department: newDepartment,
    });

    const reqOptions = {
        url: `${process.env.REACT_APP_DOMAIN}/api/v1/users/${encodeURIComponent(id)}/department`,
        method: "PUT",
        headers: headersList,
        data: bodyContent,
    };

    const responseData = axios
        .request(reqOptions)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.error(err);
            throw err;
        });

    return responseData;
}

export default ChangeDepartment;
