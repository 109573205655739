import { InputHTMLAttributes } from "react";
import { ContactMethodsT } from "../../../../types/global";
import ContactHref from "./ContactHref";
import { types } from "./SelectContactMethod";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  handleDeleteContact: (selectedContact: ContactMethodsT) => void;
  contact: ContactMethodsT;
  handleUpdateContact: (
    selectedContact: ContactMethodsT,
    value: string
  ) => void;
}

const ContactFormInput = ({
  handleDeleteContact,
  contact,
  handleUpdateContact,
  ...props
}: Props) => {
  return (
    <div
      className={`flex flex-col justify-start w-full md:max-w-[309px] min-w-[309px]`}
    >
      <label className="text-base font-bold pb-2 capitalize">
        {types.find((typeElement) => typeElement.key === contact.type)?.label}
      </label>
      <div className="flex items-center gap-2">
        <ContactHref contact={contact} />
        <input
          {...props}
          className={`w-full py-1 px-1 pl-4 border-[1px] border-theme-distributor rounded-lg`}
          defaultValue={contact.value}
          onBlur={(e) => {
            if (e.currentTarget.value.length === 0) {
              handleDeleteContact(contact);
            } else {
              handleUpdateContact(contact, e.currentTarget.value);
            }
          }}
        />
      </div>
    </div>
  );
};

export default ContactFormInput;
