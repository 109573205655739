import { useState } from "react";
import { useTranslation } from "react-i18next";
import useUserHook from "../../../../Context/UseUserContext";
import { AlertDialog } from "../../AlertDialog/AlertDialog";
import DeleteDialog from "../../AlertDialog/Dialogs/DeleteDialog";
interface Props {
  handleResetForm: () => void;
  id: string;
  formType: "people" | "companies" | "contactLists";
  name: string;
  setIsOpenForm: (value: boolean) => void;
  canEdit: boolean;
}

const FormFooterNormal = ({
  handleResetForm,
  id,
  formType,
  name,
  setIsOpenForm,
    canEdit,
}: Props) => {
  const { t } = useTranslation();
  const useUser = useUserHook();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mt-4 flex flex-row justify-end gap-4 flex-wrap">
      <button
        type="button"
        className="w-full md:w-auto border-[1px] border-theme-distributor bg-white rounded-full text-base text-theme-distributor font-bold px-8 py-1"
        onClick={(e) => {
          e.preventDefault();
          handleResetForm();
          setIsOpenForm(false);
        }}
      >
        {t("form_buttons_discard")}
      </button>
      {canEdit  && (
        <>
          <AlertDialog
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            button={
              <button className="w-full md:w-auto border-[1px] border-[#FF2B4B] bg-[#FF2B4B] rounded-full text-base text-white font-bold px-8 py-1">
                {t("form_buttons_delete")}
              </button>
            }
          >
            <DeleteDialog
              formType={formType}
              id={id}
              setIsOpen={setIsOpen}
              message={`${t("delete_dialog_company_1")} ${name} ${t(
                "delete_dialog_company_2"
              )}`}
              setIsOpenForm={setIsOpenForm}
            />
          </AlertDialog>
          <button className="w-full md:w-auto border-[1px] border-[#00D975] bg-[#00D975] rounded-full text-base text-white font-bold px-8 py-1">
            {t("form_buttons_save")}
          </button>{" "}
        </>
      )}
    </div>
  );
};

export default FormFooterNormal;
