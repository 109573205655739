import axios from "axios";
import { Item } from "../components/common/FormComponents/SelectForm/SelectForm";
import { ContactMethodsT, ComboBoxT } from "../types/global";

type MembershipFunctionsT = {
  selectedContactLists: Item | null;
  consentDate: string;
  selectedGdpr: ComboBoxT | null;
};

async function CreateFunction(
  tableName: "companies" | "people" | "contactLists" | "users" | "tags",
  bodyContent: string,
  setSelectedCompany?: ((selectedCompany: Item) => void | null) | undefined,
  contactMethods?: ContactMethodsT[] | null,
  tags?: string[] | null,
  membershipFunctions?: MembershipFunctionsT | null,
  membershipsToAdd?: string[] | null
) {
  const headersList = {
    Accept: "*/*",
    "Content-Type": "application/json",
  };

  const reqOptions = {
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/${tableName}`,
    method: "POST",
    headers: headersList,
    data: bodyContent,
  };

  axios
    .request(reqOptions)
    .then((responseCreate) => {
      if (setSelectedCompany) {
        setSelectedCompany({
          id: responseCreate.data.id,
          name: responseCreate.data.name,
        });
      }
      const id = responseCreate.data.id;

      // Create Contact Methods
      if (id && contactMethods && contactMethods.length > 0) {
        contactMethods.forEach((element) => {
          const bodyContent = JSON.stringify({
            type: element.type,
            value: element.value,
          });

          const reqOptions = {
            url: `${process.env.REACT_APP_DOMAIN}/api/v1/contacts/${id}/contactMethods`,
            method: "POST",
            headers: headersList,
            data: bodyContent,
          };

          axios
            .request(reqOptions)
            .then((responseContactMethods) => {})
            .catch((error) => {
              console.log(error);
            });
        });
      }

      // Create Tags
      if (id && tags && tags.length > 0) {
        const bodyContent = JSON.stringify(tags);

        const reqOptions = {
          url: `${process.env.REACT_APP_DOMAIN}/api/v1/contacts/${id}/tags`,
          method: "POST",
          headers: headersList,
          data: bodyContent,
        };
        axios
          .request(reqOptions)
          .then((responseTags) => {})
          .catch((error) => {
            console.log(error);
          });
      }

      // Memberships

      // Create memberships
      if (membershipFunctions?.selectedContactLists?.id?.length) {
        const bodyContent = JSON.stringify({
          contactId: id,
        });

        const reqOptions = {
          url: `${process.env.REACT_APP_DOMAIN}/api/v1/contactLists/${membershipFunctions?.selectedContactLists?.id}/memberships`,
          method: "POST",
          headers: headersList,
          data: bodyContent,
        };

        axios
          .request(reqOptions)
          .then((responseMembership) => {
            if (membershipFunctions?.consentDate?.length) {
              const bodyContent = JSON.stringify({
                consentDate: membershipFunctions?.consentDate,
                gdprOptIn: membershipFunctions?.selectedGdpr?.id,
              });

              const reqOptions = {
                url: `${process.env.REACT_APP_DOMAIN}/api/v1/memberships/${responseMembership?.data?.id}`,
                method: "PATCH",
                headers: headersList,
                data: bodyContent,
              };

              axios
                .request(reqOptions)
                .then((responseConsent) => {})
                .catch((error) => {
                  console.error(error);
                  throw error;
                });
            }
          })
          .catch((error) => {
            console.error(error);
            throw error;
          });
      }

      // Create memberships by ContactLists
      if (membershipsToAdd?.length) {
        membershipsToAdd?.forEach((elId) => {
          const bodyContent = JSON.stringify({
            contactId: elId,
          });

          const reqOptions = {
            url: `${process.env.REACT_APP_DOMAIN}/api/v1/contactLists/${id}/memberships`,
            method: "POST",
            headers: headersList,
            data: bodyContent,
          };

          axios
            .request(reqOptions)
            .then((responseMembership) => {})
            .catch((error) => {
              console.error(error);
              throw error;
            });
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export default CreateFunction;
