import {
  Cross1Icon,
  ThickArrowLeftIcon,
  ThickArrowRightIcon,
} from "@radix-ui/react-icons";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { FormProgressBar } from "../FormProgressBar";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";
import useProgressHook from "../../../../Context/UseProgressContext";
import useFormNavigation from "../../../../Context/UseFormNavigationContext";
import axios from "axios";
import ContactShortcuts from "./ContactShortcuts";
import useDeviceType from "../../../../Context/useDeviceType";

interface Props {
  titleName: string;
  type: "person" | "company" | "distributor";
  id?: string;
  subtitle_1?: string;
  subtitle_2?: string;
  titleTheme: string;
  isDoubleDialog?: boolean;
  contacts?: { email: string | undefined; phone: string | undefined };
}

const FormHeaderNormal = ({
  titleName,
  type,
  id,
  subtitle_1,
  subtitle_2,
  titleTheme,
  isDoubleDialog = false,
  contacts,
}: Props) => {
  const { t } = useTranslation();

  const progressContext = useProgressHook();
  const formNavigation = useFormNavigation();
  const device = useDeviceType();

  useEffect(() => {
    progressContext?.setType(type);
  }, [progressContext, type]);

  const handleVcf = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DOMAIN}/api/v1/${
          type === "company" ? "companies" : "people"
        }/${id}`,
        {
          headers: {
            Accept: "text/vcf",
            "Access-Control-Allow-Origin": "*",
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "text/vcard;charset=utf-8",
        });

        if (navigator?.msSaveBlob) {
          // For ie and Edge
          return navigator.msSaveBlob(blob, titleName);
        } else {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = titleName;
          document.body.appendChild(link);
          link.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
          link.remove();
          window.URL.revokeObjectURL(link.href);
        }
      })
      .catch((error) => console.log(error));
  }, [id, titleName, type]);

  const handleDocx = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DOMAIN}/api/v1/contacts/${id}`, {
        headers: {
          Accept: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "Access-Control-Allow-Origin": "*",
        },
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });

        if (navigator?.msSaveBlob) {
          // For ie and Edge
          return navigator.msSaveBlob(blob, titleName);
        } else {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = titleName;
          document.body.appendChild(link);
          link.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
          link.remove();
          window.URL.revokeObjectURL(link.href);
        }
      })
      .catch((error) => console.log(error));
  }, [id, titleName]);

  const DesktopHeaderBtns = () => {
    return (
      <div className="w-full flex flex-row justify-between gap-4 mb-4">
        <ContactShortcuts contacts={contacts} />
        <div className="flex gap-4">
          {(type === "person" || type === "company") && (
            <div className="flex gap-4">
              <button
                className="border-[1px] disabled:border-theme-distributor bg-white rounded-full text-base disabled:text-theme-distributor font-bold px-8 py-1 border-gray-600 text-gray-600 hover:bg-gray-100"
                onClick={(e) => {
                  e.preventDefault();
                  handleVcf();
                }}
              >
                {t("form_person_header_button_download")}
              </button>
              <button
                className="border-[1px] disabled:border-theme-distributor bg-white rounded-full text-base disabled:text-theme-distributor font-bold px-8 py-1 border-gray-600 text-gray-600 hover:bg-gray-100"
                onClick={(e) => {
                  e.preventDefault();
                  handleDocx();
                }}
              >
                {t("form_header_button_print")}
              </button>
            </div>
          )}

          {!isDoubleDialog && (
            <>
              <button
                className="inline-flex items-center justify-center border-[1px] border-gray-600 rounded-full p-2 text-gray-600 hover:bg-gray-100 disabled:text-theme-distributor disabled:border-theme-distributor disabled:hover:bg-white"
                onClick={(e) => {
                  e.preventDefault();
                  formNavigation?.setCurrentItem(formNavigation?.prevItem);
                }}
                disabled={!formNavigation?.prevItem}
              >
                <ThickArrowLeftIcon className="h-4 w-4" />
              </button>
              <button
                className="inline-flex items-center justify-center border-[1px] border-gray-600 rounded-full p-2 text-gray-600 hover:bg-gray-100 disabled:text-theme-distributor disabled:border-theme-distributor disabled:hover:bg-white"
                onClick={(e) => {
                  e.preventDefault();
                  formNavigation?.setCurrentItem(formNavigation?.nextItem);
                }}
                disabled={!formNavigation?.nextItem}
              >
                <ThickArrowRightIcon className="h-4 w-4" />
              </button>
            </>
          )}
          <DialogPrimitive.Close className="inline-flex items-center justify-center border-[1px] border-gray-600 rounded-full p-2 text-gray-600 hover:bg-gray-100 disabled:text-theme-distributor disabled:border-theme-distributor disabled:hover:bg-white">
            <Cross1Icon className="h-4 w-4" />
          </DialogPrimitive.Close>
        </div>
      </div>
    );
  };

  const MobileHeaderBtns = () => {
    return (
      <div className="w-full flex flex-col gap-4 mb-4">
        <div className="flex justify-between">
          <div className="flex">
            <ContactShortcuts contacts={contacts} />
          </div>
          <div className="flex gap-4">
            {!isDoubleDialog && (
              <>
                <button
                  className="inline-flex items-center justify-center border-[1px] border-gray-600 rounded-full p-2 text-gray-600 hover:bg-gray-100 disabled:text-theme-distributor disabled:border-theme-distributor disabled:hover:bg-white"
                  onClick={(e) => {
                    e.preventDefault();
                    formNavigation?.setCurrentItem(formNavigation?.prevItem);
                  }}
                  disabled={!formNavigation?.prevItem}
                >
                  <ThickArrowLeftIcon className="h-4 w-4" />
                </button>
                <button
                  className="inline-flex items-center justify-center border-[1px] border-gray-600 rounded-full p-2 text-gray-600 hover:bg-gray-100 disabled:text-theme-distributor disabled:border-theme-distributor disabled:hover:bg-white"
                  onClick={(e) => {
                    e.preventDefault();
                    formNavigation?.setCurrentItem(formNavigation?.nextItem);
                  }}
                  disabled={!formNavigation?.nextItem}
                >
                  <ThickArrowRightIcon className="h-4 w-4" />
                </button>
              </>
            )}
            <DialogPrimitive.Close className="inline-flex items-center justify-center border-[1px] border-gray-600 rounded-full p-2 text-gray-600 hover:bg-gray-100 disabled:text-theme-distributor disabled:border-theme-distributor disabled:hover:bg-white">
              <Cross1Icon className="h-4 w-4" />
            </DialogPrimitive.Close>
          </div>
        </div>

        {(type === "person" || type === "company") && (
          <div className="flex justify-start gap-4">
            <button
              className="border-[1px] disabled:border-theme-distributor bg-white rounded-full text-base disabled:text-theme-distributor font-bold px-8 py-1 border-gray-600 text-gray-600 hover:bg-gray-100"
              onClick={(e) => {
                e.preventDefault();
                handleVcf();
              }}
            >
              {t("form_person_header_button_download")}
            </button>
            <button
              className="border-[1px] disabled:border-theme-distributor bg-white rounded-full text-base disabled:text-theme-distributor font-bold px-8 py-1 border-gray-600 text-gray-600 hover:bg-gray-100"
              onClick={(e) => {
                e.preventDefault();
                handleDocx();
              }}
            >
              {t("form_header_button_print")}
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {device === "Phone" ? <MobileHeaderBtns /> : <DesktopHeaderBtns />}
      <div className="flex md:flex-nowrap flex-wrap w-full pb-8">
        <div className="flex flex-col w-full justify-start md:border-r-[1px] border-separate">
          <h1 className={`text-4xl ${titleTheme} font-bold py-4 break-all`}>
            {titleName}
          </h1>
          {(type === "person" || type === "distributor") && (
            <span className="font-bold text-base">{subtitle_1}</span>
          )}
          {type === "person" && <span className="text-base">{subtitle_2}</span>}
        </div>
        <FormProgressBar
          type={type}
          theme={{
            bgColor:
              type === "person"
                ? "bg-theme-person"
                : type === "company"
                ? "bg-theme-company"
                : "bg-theme-distributor",
            borderColor:
              type === "person"
                ? "border-theme-person"
                : type === "company"
                ? "border-theme-company"
                : "border-theme-distributor",
            textColor:
              type === "person"
                ? "text-theme-person"
                : type === "company"
                ? "text-theme-company"
                : "text-theme-distributor",
          }}
        />
      </div>
    </>
  );
};

export default FormHeaderNormal;
