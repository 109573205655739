import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en_us from "./en/en.us.json";
import de from "./de/de.json";

const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: options,
    fallbackLng: "en",
    debug: false,
    resources: {
      en: en_us,
      de: de,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
