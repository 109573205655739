import { EnvelopeClosedIcon, MobileIcon } from "@radix-ui/react-icons";
import { MouseEvent } from "react";

interface Props {
    contacts?: { email: string | undefined; phone: string | undefined};
}

const ContactShortcuts = ({ contacts }: Props) => {

  const handleLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (!contacts?.email || !contacts?.phone) {
      event.preventDefault();
    }
  };

  return (
    <div className="flex gap-4">
      <button
        disabled={!contacts?.email}
        className="h-fit inline-flex items-center justify-center border-[1px] border-gray-600 rounded-full p-2 text-gray-600 hover:bg-gray-100 disabled:text-theme-distributor disabled:border-theme-distributor disabled:hover:bg-white"
      >
        <a href={`mailto:${contacts?.email}`} target="_blank" rel="noreferrer" onClick={handleLinkClick}>
          <EnvelopeClosedIcon className="h-4 w-4" />
        </a>
      </button>

      <button
        disabled={!contacts?.phone}
        className="h-fit inline-flex items-center justify-center border-[1px] border-gray-600 rounded-full p-2 text-gray-600 hover:bg-gray-100 disabled:text-theme-distributor disabled:border-theme-distributor disabled:hover:bg-white"
      >
        <a href={`tel:${contacts?.phone}`} target="_blank" rel="noreferrer" onClick={handleLinkClick}>
          <MobileIcon className="h-4 w-4" />
        </a>
      </button>
    </div>
  );
};

export default ContactShortcuts;
