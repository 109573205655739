import { Transition } from "@headlessui/react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import clsx from "clsx";

import { Fragment } from "react";
import CompanyForm from "../../main/company/CompanyForm";
import DistributorForm from "../../main/distributor/DistributorForm";
import PersonForm from "../../main/person/PersonForm";
import { Item } from "../FormComponents/SelectForm/SelectForm";
import { Content as ContentCompaniesT } from "../../../types/Companies";
import { Content as ContentContactListT } from "../../../types/ContactLists";
import { Content as ContentPeopleT } from "../../../types/People";

interface DialogProps {
  button: JSX.Element;
  formType: "person" | "company" | "distributor";
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setSelectedCompany?: ((selectedCompany: Item) => void | null) | undefined; // only for Person Form.
  edit?: ContentPeopleT | ContentContactListT | ContentCompaniesT | any;
  selectedPerson?: string;
  independent?: boolean;
}

const Dialog = ({
  button,
  formType,
  isOpen,
  setIsOpen,
  setSelectedCompany,
  edit,
  selectedPerson,
  independent,
}: DialogProps) => {
  return (
    <DialogPrimitive.Root open={isOpen} onOpenChange={setIsOpen}>
      <DialogPrimitive.Trigger asChild>{button}</DialogPrimitive.Trigger>
      <DialogPrimitive.Portal forceMount>
        <Transition.Root show={isOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <DialogPrimitive.Overlay
              forceMount
              className="fixed inset-0 z-20 bg-black/50 overflow-y-auto grid"
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPrimitive.Content
                  forceMount
                  className={clsx(
                    "relative z-50",
                    "w-[95vw] max-w-6xl rounded-lg p-4 md:p-12 md:w-full h-auto my-8",
                    "top-[49%] md:top-[48%] left-[50%] -translate-x-[50%] -translate-y-[50%]",
                    "bg-white",
                    "focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                  )}
                >
                  {formType === "person" && (
                    <PersonForm
                      setIsOpen={setIsOpen}
                      edit={edit}
                      selectedPerson={selectedPerson}
                      independent={independent}
                    />
                  )}
                  {formType === "company" && (
                    <CompanyForm
                      setIsOpen={setIsOpen}
                      setSelectedCompany={setSelectedCompany}
                      edit={edit}
                      independent={independent}
                    />
                  )}
                  {formType === "distributor" && (
                    <DistributorForm
                      setIsOpen={setIsOpen}
                      edit={edit}
                      independent={independent}
                    />
                  )}
                </DialogPrimitive.Content>
              </Transition.Child>
            </DialogPrimitive.Overlay>
          </Transition.Child>
        </Transition.Root>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
};

export { Dialog };
