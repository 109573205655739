import { Transition } from "@headlessui/react";
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import { clsx } from "clsx";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import ChangeUserDepartmentDialog from "./Dialogs/ChangeUserDepartmentDialog";

interface AlertDialogProps {
    button: JSX.Element;
    customClass?: string;
    id: string;
    name: string;
    oldName: string;
    email: string;
}

const AlertDialogDepartment = ({
                             button,
                             customClass,
                             id,
                             name,
                             oldName,
                             email,
                         }: AlertDialogProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { t } = useTranslation();

    return (
        <AlertDialogPrimitive.Root open={isOpen} onOpenChange={setIsOpen}>
            <AlertDialogPrimitive.Trigger asChild>
                {button}
            </AlertDialogPrimitive.Trigger>
            <AlertDialogPrimitive.Portal forceMount>
                <Transition.Root show={isOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <AlertDialogPrimitive.Overlay
                            forceMount
                            className="fixed inset-0 z-20 bg-black/90"
                        />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <AlertDialogPrimitive.Content
                            forceMount
                            className={clsx(
                                "fixed z-50",
                                "w-[95vw] max-w-xl rounded-lg p-4 md:w-full",
                                "top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]",
                                "bg-white",
                                "focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75",
                                `${customClass}`
                            )}
                        >
                            <ChangeUserDepartmentDialog
                                id={id}
                                name={name}
                                setIsOpen={setIsOpen}
                                message={`${t("users_change_department_dialog_message")
                                    .replace("#", oldName)
                                    .replace("##", name)} ${email}`}
                            />
                        </AlertDialogPrimitive.Content>
                    </Transition.Child>
                </Transition.Root>
            </AlertDialogPrimitive.Portal>
        </AlertDialogPrimitive.Root>
    );
};

export { AlertDialogDepartment };
